import React from "react";
import { FcBinoculars } from "react-icons/fc";
import AnimatedButton from "./AnimatedButton";
import { BiSolidBinoculars } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
function NotFound({ results = null }) {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col justify-center items-center mt-8 md:mt-24">
      {/* <FcBinoculars className="size-[100px] text-grey1" /> */}
      <BiSolidBinoculars className="size-[100px] !text-primary" />
      {results && (
        <>
          <p>There are no results for {`"results"`}</p>
          <p>- Check your spelling for typing errors</p>
          <p>- Try searching with short and simple keywords</p>
          <p>
            - Try searching more general terms - you can then filter the search
            results
          </p>
        </>
      )}
      <p className="text-grey1 mt-4 text-lg">No results found</p>

      <AnimatedButton
        className="cursor-pointer bg-darkGreen1 rounded flex items-center justify-center !p-3 text-white drop-shadow-lg !mt-2"
        onClick={() => {
          navigate("/");
        }}
      >
        <span className="font-extralight">Go To Homepage</span>
      </AnimatedButton>
    </div>
  );
}

export default NotFound;
