import Facebook from "../../assets/images/facebook.png";
import "../component.css";
import Modal from "./Modal";
import AnimatedButton from "../AnimatedButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useState } from "react";
import axios from "../../api/axios";
import { toast } from "sonner";
import { SyncLoader } from "react-spinners";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import moment from "moment";
import { MdCancel } from "react-icons/md";
import { states, LGA } from "../../tools/states";

const schema = yup.object().shape({
  fullName: yup
    .string()
    .required("First name and last name are required")
    .test("two-words", "Full name must contain at least two words", (value) => {
      if (!value) return false;
      const parts = value.trim().split(" ");
      return parts.length >= 2 && parts[0].length > 0 && parts[1].length > 0;
    }),
  email: yup.string().email("Invalid email").required("Email is required"),
  phoneNumber: yup
    .string()
    .max(11, "Must be exactly 11 digits")
    // .max(13, 'Must be exactly 13 digits')
    .matches(/^[0-9]+$/, "Enter valid phone number")
    .required("Phone number is required"),

  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]+$/,
      "Password must contain at least one number, one capital letter and a special character."
    ),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  address: yup.string().required("Address is required"),
  dob: yup
    .string()
    .required("Date of Birth is required")
    .test("is-13", "You must be at least 13 years old", (value) => {
      return moment().diff(moment(value, "YYYY-MM-DD"), "years") >= 13;
    }),
  state: yup.string().required("State is required"),
  city: yup.string().required("city is required"),
});

export default function SignUp({
  show,
  onClose,
  openSignInModal,
  openMerchantSignUpModal,
  openSignUpSuccessModal,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const [date, setDate] = useState("");
  const [state, setState] = useState(states.slice());
  const [LocalGovernments, setLocalGovernments] = useState([]);

  const [userType, setUserType] = useState("endusers");

  const toggleViewPassword = () => {
    setViewPassword((prev) => !prev);
  };
  const toggleViewConfirmPassword = () => {
    setViewConfirmPassword((prev) => !prev);
  };
  const handleStateChange = (state) => {
    const selectedLGA = LGA[state.target.value];
    setLocalGovernments(selectedLGA);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const capitalizeString = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const onSubmit = (data) => {
    const dateArray = data.dob.split("-");
    const formattedDate = date
      ? `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`
      : "";
    const formattedNameArr = data.fullName.split(" ");
    const FormattedAddress = `${data.address}|${data.state}|${data.city}`;
    const requestData = {
      firstname: capitalizeString(formattedNameArr[0]),
      lastname: capitalizeString(formattedNameArr[1]),
      dob: formattedDate,
      email: data.email,
      password: data.password,
      confirm_password: data.confirm_password,
      address: FormattedAddress,
      state: data.state,
      city: data.city,
      phoneNumber: data.phoneNumber,
      user_type: userType,
    };
    console.log("this is the sign up data with user type", requestData);

    setIsLoading(true);

    axios
      .post("/users/reg", requestData)
      .then((response) => {
        if (response.data.status === "200") {
          openSignUpSuccessModal();
          reset();
        } else {
          if (response.data.message.startsWith("Validation")) {
            Object.keys(response.data.errors).forEach((key) => {
              toast.error(`${response.data.errors[key][0]}`);
            });
          } else {
            console.log(response);
            toast.error(`${response.data.message}`);
          }
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("An error occurred. Please try again.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal show={show} onClose={onClose}>
      <div className="flex justify-end text-darkGreen1">
        <MdCancel className="w-20 h-20" onClick={onClose} />
      </div>
      <h2 className="text-[20px] md:text-[24px] leading-[28px] md:leading-[33.6px] tracking-[-.2px] mb-[24px]">
        Sign Up
      </h2>
      <form
        className="flex flex-col gap-[16px]"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col gap-[8px]">
          <label
            htmlFor="name"
            className={`text-[14px] leading-[16.8px] md:text-[16px] md:leading-[19.2px] tracking-[-.2px]`}
          >
            Full Name
          </label>
          <input
            className={`h-[49px] bg-dGrey2 outline-none ${
              errors.fullName ? "border-[1px] border-red1" : "border-[0px]"
            } rounded-[12px] py-[10px] px-[16px] text-[14px] leading-[22.4px] capitalize`}
            type="text"
            id="name"
            name="username"
            placeholder="Enter your first and last name"
            {...register("fullName")}
          />
          {errors.fullName && (
            <p className="text-xs text-red1">{errors.fullName?.message}</p>
          )}
        </div>
        <div className="flex flex-col lg:flex-row gap-[8px] lg:gap-[11px]">
          <div className="flex flex-col gap-[8px] lg:w-[40%]">
            <label
              htmlFor="phone"
              className="text-[14px] leading-[16.8px] md:text-[16px] md:leading-[19.2px] tracking-[-.2px] "
            >
              Phone Number
            </label>
            <input
              className={`h-[49px] bg-dGrey2 outline-none ${
                errors.phoneNumber ? "border-[1px] border-red1" : "border-[0px]"
              } rounded-[12px] py-[10px] px-[16px] text-[14px] leading-[22.4px]`}
              type="number"
              id="phone"
              placeholder="Enter your phone"
              {...register("phoneNumber")}
            />
            {errors.phoneNumber && (
              <p className="text-xs text-red1">{errors.phoneNumber?.message}</p>
            )}
          </div>
          <div className="flex flex-col gap-[8px] lg:w-[57%]">
            <label
              htmlFor="email"
              className="text-[14px] leading-[16.8px] md:text-[16px] md:leading-[19.2px] tracking-[-.2px] "
            >
              Email
            </label>
            <input
              className={`h-[49px] bg-dGrey2 outline-none ${
                errors.email ? "border-[1px] border-red1" : "border-[0px]"
              } rounded-[12px] py-[10px] px-[16px] text-[14px] leading-[22.4px]`}
              type="email"
              id="email"
              name="email"
              placeholder="123@example.com"
              {...register("email")}
            />
            {errors.email && (
              <p className="text-xs text-red1">{errors.email?.message}</p>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-[8px]">
          <label
            htmlFor="password"
            className="text-[14px] leading-[16.8px] md:text-[16px] md:leading-[19.2px] tracking-[-.2px]"
          >
            Password
          </label>
          <div
            className={`flex justify-between items-center h-[49px] bg-dGrey2 outline-none ${
              errors.password ? "border-[1px] border-red1" : "border-[0px]"
            } rounded-[12px] py-[10px] px-[16px] text-[14px] leading-[22.4px]`}
          >
            <input
              className="customInput bg-dGrey2 outline-none border-[0px] w-[75%]"
              type={viewPassword ? "text" : "password"}
              style={{ fontSize: "25px" }}
              id="password"
              name="password"
              placeholder="Enter your password"
              {...register("password")}
            />
            {viewPassword ? (
              <IoMdEye
                onClick={toggleViewPassword}
                className="cursor-pointer h-full w-fit"
              />
            ) : (
              <IoMdEyeOff
                onClick={toggleViewPassword}
                className="cursor-pointer h-full w-fit"
              />
            )}
          </div>
          {errors.password && (
            <p className="text-xs text-red1">{errors.password?.message}</p>
          )}
        </div>
        <div className="flex flex-col gap-[8px]">
          <label
            htmlFor="confirm-password"
            className="text-[14px] leading-[16.8px] md:text-[16px] md:leading-[19.2px] tracking-[-.2px]"
          >
            Confirm Password
          </label>
          <div
            className={`flex justify-between items-center h-[49px] bg-dGrey2 outline-none ${
              errors.confirm_password
                ? "border-[1px] border-red1"
                : "border-[0px]"
            } rounded-[12px] py-[10px] px-[16px] text-[14px] leading-[22.4px]`}
          >
            <input
              className="customInput bg-dGrey2 outline-none border-[0px] w-[75%]"
              type={viewConfirmPassword ? "text" : "password"}
              style={{ fontSize: "25px" }}
              id="confirm-password"
              name="password"
              placeholder="Confirm your password"
              {...register("confirm_password")}
            />
            {viewConfirmPassword ? (
              <IoMdEye
                onClick={toggleViewConfirmPassword}
                className="cursor-pointer h-full w-fit"
              />
            ) : (
              <IoMdEyeOff
                onClick={toggleViewConfirmPassword}
                className="cursor-pointer h-full w-fit"
              />
            )}
          </div>
          {errors.confirm_password && (
            <p className="text-xs text-red1">
              {errors.confirm_password?.message}
            </p>
          )}
        </div>
        <div className="flex flex-col gap-[8px]">
          <label
            htmlFor="dob"
            className="text-[14px] leading-[16.8px] md:text-[16px] md:leading-[19.2px] tracking-[-.2px]"
          >
            Date of Birth
          </label>
          <input
            className={`h-[49px] bg-dGrey2 outline-none ${
              errors.dob ? "border-[1px] border-red1" : "border-[0px]"
            } rounded-[12px] py-[10px] px-[16px] text-[14px] leading-[22.4px] w-full`}
            type="date"
            id="dob"
            {...register("dob")}
            onChange={(e) => setDate(e.target.value)}
          />
          {errors.dob && (
            <p className="text-xs text-red1">{errors.dob?.message}</p>
          )}
        </div>
        <div className="flex flex-col gap-[8px]">
          <label
            htmlFor="state"
            className="text-[14px] leading-[16.8px] md:text-[16px] md:leading-[19.2px] tracking-[-.2px] "
          >
            State of Residence
          </label>
          <select
            className={`h-[49px] bg-dGrey2 outline-none ${
              errors.state ? "border-[1px] border-red1" : "border-[0px]"
            } rounded-[12px] py-[10px] px-[16px] text-[14px] leading-[22.4px]`}
            id="state"
            {...register("state")}
            onChange={handleStateChange}
          >
            <option value="">Choose State</option>
            {state.map((individualState) => (
              <option key={individualState} value={individualState}>
                {individualState}
              </option>
            ))}
          </select>
          {errors.state && (
            <p className="text-xs text-red1">{errors.state?.message}</p>
          )}
        </div>

        <div className="flex flex-col gap-[8px]">
          <label
            htmlFor="city"
            className="text-[14px] leading-[16.8px] md:text-[16px] md:leading-[19.2px] tracking-[-.2px] "
          >
            City
          </label>
          <select
            className={`h-[49px] bg-dGrey2 outline-none ${
              errors.city ? "border-[1px] border-red1" : "border-[0px]"
            } rounded-[12px] py-[10px] px-[16px] text-[14px] leading-[22.4px]`}
            id="city"
            {...register("city")}
          >
            <option value="">Choose city</option>
            {LocalGovernments.map((LocalGovernment) => (
              <option key={LocalGovernment} value={LocalGovernment}>
                {LocalGovernment}
              </option>
            ))}
          </select>
          {errors.city && (
            <p className="text-xs text-red1">{errors.city?.message}</p>
          )}
        </div>
        <div className="flex flex-col gap-[8px]">
          <label
            htmlFor="address"
            className="text-[14px] leading-[16.8px] md:text-[16px] md:leading-[19.2px] tracking-[-.2px]"
          >
            Address
          </label>
          <textarea
            className={`h-[49px] bg-dGrey2 outline-none ${
              errors.address ? "border-[1px] border-red1" : "border-[0px]"
            } rounded-[12px] py-[10px] px-[16px] text-[14px] leading-[22.4px] resize-none`}
            id="address"
            name="address"
            placeholder="Enter your address"
            {...register("address")}
          />
          {errors.address && (
            <p className="text-xs text-red1">{errors.address?.message}</p>
          )}
        </div>
        <div className="flex flex-col gap-[8px]">
          <label
            htmlFor="user-type"
            className="text-[14px] leading-[16.8px] md:text-[16px] md:leading-[19.2px] tracking-[-.2px]"
          >
            User Type
          </label>
          <select
            id="user-type"
            className="h-[49px] bg-dGrey2 outline-none rounded-[12px] py-[10px] px-[16px] text-[14px] leading-[22.4px]"
            value={userType}
            onChange={(e) => setUserType(e.target.value)}
          >
            <option value="endusers">End User</option>
            <option value="subdealers">Subdealers</option>
            <option value="retailers">Retailer</option>
          </select>
        </div>

        {/* <div className="flex justify-between">
          <div
            className="text-[14px] text-darkGreen3 leading-[22.4px] cursor-pointer"
            onClick={openMerchantSignUpModal}
          >
            Sign Up as Merchant
          </div>
          <div
            className="text-[14px] text-grey1 leading-[22.4px] cursor-pointer"
            onClick={openSignInModal}
          >
            Sign In
          </div>
        </div> */}
        <button type="submit" className="border-0 outline-none !mt-4 !mb-2">
          <AnimatedButton className="cursor-pointer text-[14px] lg:text-[18px] leading-[19.6px] lg:leading-[25.2px] tracking-[-.2px] w-full h-[48px] bg-darkGreen1 flex items-center justify-center text-[white] rounded-[8px]">
            {isLoading ? <SyncLoader color="#fff" size="12px" /> : "Sign Up"}
          </AnimatedButton>
        </button>
        {/* <div className="h-[39px] flex justify-center items-center relative">
          <div className="absolute w-full h-[2px] bg-dGrey1 z-10"></div>
          <div className="text-grey1 text-[12px] md:text-[14px] leading-[19.2px] md:leading-[22.4px] z-20 p-[10px] bg-[white]">
            Or using other method
          </div>
        </div> */}
        {/* <div className="h-[48px] border border-dGrey1 rounded-[8px] flex justify-center items-center gap-[8px] cursor-pointer">
          <div className="size-[14px]">
            <img src={Facebook} alt="" className="size-full object-contain" />
          </div>
          <div className="text-[14px] leading-[22.4px] md:text-[16px] md:leading-[25.6px]">
            Sign Up with Facebook
          </div>
        </div> */}
      </form>
    </Modal>
  );
}
