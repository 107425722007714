import { createContext, useEffect } from "react";
const RecieptContext = createContext();
import { useState } from "react";

export const RecieptProvider = ({ children }) => {
  const [reciept, setReciept] = useState(() => {
    const joatech_reciept = localStorage.getItem("joatech_reciept");
    return joatech_reciept ? JSON.parse(joatech_reciept) : [];
  });

  const [recieptType, setRecieptType] = useState("");
  const [recieptStatus, setRecieptStatus] = useState("");

  return (
    <RecieptContext.Provider
      value={{
        reciept,
        recieptType,
        recieptStatus,
        setRecieptType,
        setRecieptStatus,
        setReciept,
      }}
    >
      {children}
    </RecieptContext.Provider>
  );
};

export default RecieptContext;
