import { createContext, useState } from "react";
export const PhysicalAirtimeContext = createContext();
export const PhysicalAirtimeProvider = ({ children }) => {
  const [selectedAddress, setSelectedAddress] = useState("");

  const [deliveryType, setDeliveryType] = useState("");
  const [deliveryToDisplay, setDeliveryToDisplay] = useState("");
  const [commissions, setCommissions] = useState([]);
  return (
    <PhysicalAirtimeContext.Provider
      value={{
        selectedAddress,
        deliveryToDisplay,
        deliveryType,
        commissions,
        setCommissions,
        setDeliveryToDisplay,
        setSelectedAddress,
        setDeliveryType,
      }}
    >
      {children}
    </PhysicalAirtimeContext.Provider>
  );
};
