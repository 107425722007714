// axiosInstance.js
import axios from "axios";
import { API_ROUTES } from "../constants/server";
import Cookies from "js-cookie";
import { logoutUser } from "../context/AuthContext";

const axiosInstance = axios.create({
  baseURL: API_ROUTES.SERVER_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get("token");
    if (token) {
      config.headers.logintoken = `${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (
      response.data.status?.toString() === "302" || response.data.message === "Authentication Failed"
     
    ) {
      logoutUser(); // Call the logout utility
     
      return Promise.reject({
        response: {
          status: 300, // or any other custom status you prefer
          data: response.data,
        },
      });
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
