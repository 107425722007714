import { useEffect, useRef } from "react";
import { BodyBottom, NavBarLogo, StoreImg, StoreImg2 } from "../all_images";
import logo from "../assets/images/FooterLogo.png";
import { Link } from "react-router-dom";
import { ApiRoutes } from "../tools/apiRoutes";
import axiosInstance from "../api/axios";
import { useState } from "react";

const Footer = () => {
  const offset = 50;

  const scrollToTop = (event) => {
    event.preventDefault();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > offset) {
        document
          .querySelector(".progress-wrap")
          .classList.add("active-progress");
      } else {
        document
          .querySelector(".progress-wrap")
          .classList.remove("active-progress");
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [loading, setLoading] = useState(false);
  const [appInfo, setAppInfo] = useState(null);
  const getInfo = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(ApiRoutes.appInfo);
      if (Number(response.data.status) === 200) {
        setAppInfo(response.data.data);
      } else {
        console.error(response);
      }
    } catch (e) {
      console.error("couldn't create address", e);
      return false;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <>
      <footer className="footer py-36_ pt-[36px] pb-[18px] mt-[30px] px-0">
        <img src={BodyBottom} alt="BG" className="body-bottom-bg" />
        <div className="container lg:max-w-[90%] md:max-w-[96%] xs:max-w-[96%]">
          <div className="footer-item-wrapper d-flex align-items-start flex-wrap">
            <div className="footer-item">
              <div className="col-4">
                <div className="footer-item__logo">
                  <div className="row">
                    <div className="col-3">
                      <Link to="/" className="bg-white rounded-full px-0 py-8 ">
                        {" "}
                        <img src={NavBarLogo} alt="" />
                      </Link>
                    </div>
                    <div className="col-9 flex flex-between pt-0 px-0 ">
                      <h5 className="text-[27px] max-sm:text-[24px] text-[#ddd]">
                        Joatech
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-4 col-sm-12 md:mb-0 xs:mb-6">
                  <p className="mb-24 custom-font-normal text-[#ccc]">
                    The biggest marketplace managed by Joatech Technologies,
                    which provides various kinds of daily needs and hobbies
                  </p>
                  <div className="flex-align gap-12 mb-10">
                    <span className="w-32 h-32 flex-center rounded-circle bg-main-600 text-white text-md flex-shrink-0">
                      <i className="ph-fill ph-phone-call"></i>
                    </span>
                    <div className="flex-align gap-8 flex-wrap">
                      <Link
                        href={`tel:${appInfo?.phone_number}`}
                        className="text-[14px] text-[#ccc] hover-text-main-200"
                      >
                        {appInfo?.phone_number || "Not Available"}
                      </Link>
                      <span className="text-[13px] text-main-300">or</span>
                      <Link
                        href={`tel:${appInfo?.whatsapp_number}`}
                        className="text-[14px] text-[#ccc] hover-text-main-200"
                      >
                        {appInfo?.whatsapp_number || "Not Available"}
                      </Link>
                    </div>
                  </div>

                  <div className="flex-align gap-12 mb-16">
                    <span className="w-32 h-32 flex-center rounded-circle bg-main-600 text-white text-md flex-shrink-0">
                      <i className="ph-fill ph-envelope"></i>
                    </span>
                    <Link
                      href={`${appInfo?.email || "Not Available"}`}
                      className="text-[14px] text-[#ccc] hover-text-main-200"
                    >
                      {appInfo?.email || "Not Available"}
                    </Link>
                  </div>

                  <div className="flex-align gap-12 mb-16 mt-28">
                    <ul className="flex-align gap-10">
                      <li>
                        <Link
                          href="https://www.facebook.com/"
                          className="w-44 h-44 flex-center bg-main-100 text-main-600 text-xl rounded-circle hover-bg-main-600 hover-text-white"
                        >
                          <i className="ph-fill ph-facebook-logo"></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href="https://www.twitter.com/"
                          className="w-44 h-44 flex-center bg-main-100 text-main-600 text-xl rounded-circle hover-bg-main-600 hover-text-white"
                        >
                          <i className="ph-fill ph-twitter-logo"></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href="https://www.linkedin.com/"
                          className="w-44 h-44 flex-center bg-main-100 text-main-600 text-xl rounded-circle hover-bg-main-600 hover-text-white"
                        >
                          <i className="ph-fill ph-instagram-logo"></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href="https://www.pinterest.com/"
                          className="w-44 h-44 flex-center bg-main-100 text-main-600 text-xl rounded-circle hover-bg-main-600 hover-text-white"
                        >
                          <i className="ph-fill ph-linkedin-logo"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4 col-6 md:mb-0 xs:mb-6">
                  <h6 className="footer-item__title text-[19px]">
                    Information
                  </h6>
                  <ul className="footer-menu mt-20">
                    <li className="mb-9">
                      <Link
                        to="/"
                        className="text-gray-200 hover-text-main-200 custom-font-normal"
                      >
                        Become a Vendor
                      </Link>
                    </li>
                    <li className="mb-9">
                      <Link
                        to="/"
                        className="text-gray-200 hover-text-main-200 custom-font-normal custom-font-normal"
                      >
                        Privacy Policy
                      </Link>
                    </li>
                    <li className="mb-9">
                      <Link
                        to="/"
                        className="text-gray-200 hover-text-main-200 custom-font-normal"
                      >
                        Our Vendors
                      </Link>
                    </li>
                    <li className="mb-9">
                      <Link
                        to="/"
                        className="text-gray-200 hover-text-main-200 custom-font-normal"
                      >
                        About Us
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4 col-6 md:mb-0 xs:mb-6">
                  <h6 className="footer-item__title text-[19px]">Support</h6>
                  <ul className="footer-menu mt-20">
                    <li className="mb-9">
                      <Link
                        to="/"
                        className="text-gray-200 hover-text-main-200 custom-font-normal"
                      >
                        Help Center
                      </Link>
                    </li>
                    <li className="mb-9">
                      <Link
                        href="contact.html"
                        className="text-gray-200 hover-text-main-200 custom-font-normal"
                      >
                        Contact Us
                      </Link>
                    </li>
                    <li className="mb-9">
                      <Link
                        to="/"
                        className="text-gray-200 hover-text-main-200 custom-font-normal"
                      >
                        Report Item
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                  <h6 className="footer-item__title text-[19px]">My Account</h6>
                  <ul className="footer-menu mt-20">
                    <li className="mb-9">
                      <Link
                        to="/"
                        className="text-gray-200 hover-text-main-200 custom-font-normal"
                      >
                        My Account
                      </Link>
                    </li>
                    <li className="mb-9">
                      <Link
                        to="/"
                        className="text-gray-200 hover-text-main-200 custom-font-normal"
                      >
                        Order History
                      </Link>
                    </li>
                    <li className="mb-9">
                      <Link
                        to="/"
                        className="text-gray-200 hover-text-main-200 custom-font-normal"
                      >
                        Shoping Cart
                      </Link>
                    </li>
                    <li className="">
                      <Link
                        to="/"
                        className="text-gray-200 hover-text-main-200 custom-font-normal"
                      >
                        Wishlist
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-2 col-md-3 col-sm-6 col-6">
                  <h6 className="text-[19px] footer-item__title">
                    Shop on The Go
                  </h6>
                  <p className="mb-16 mt-14 text-gray-200">
                    Joatech App is available. Get it now
                  </p>
                  <div className="flex-align gap-8 my-16">
                    <a
                      href="https://apps.apple.com/ng/app/pinspay/id6453637586"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={StoreImg} alt="" />
                    </a>

                    <a
                      href="https://play.google.com/store/apps/details?id=com.pinspay"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={StoreImg2} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="container container-lg">
              <div className="bottom-footer__inner flex-wrap gap-16">
                <p className="bottom-footer__text text-center text-[11px] p-0 text-[#888] custom-font-normal">
                  COPYRIGHT &copy; JOATECH GLOBAL LINK INVESTMENT LTD. ALL
                  RIGHTS RESERVED{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="progress-wrap" onClick={scrollToTop}></div>
    </>
  );
};

export default Footer;
