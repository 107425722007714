import { useModal } from "../../hooks/useModal";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import MerchantSignUp from "./MerchantSignUp";
import SignUpSuccess from "./SignUpSuccess";
import ForgotPassword from "./ForgotPassword.jsx";
import ChooseSignUp from "./ChooseSignUp.jsx";
import CreateAddress from "./CreateAddress.jsx";
import EditAddress from "./EditAddress.jsx";
import TokenModal from "./TokenModal.jsx";
import ForgotToken from "./ForgotToken.jsx";

const Modals = () => {
  const {
    activeModal,
    closeModal,
    openModal,
    modalPath,
    setModalPath,
    editFormObject,
    setEditFormObject,
  } = useModal();

  return (
    <>
      <SignIn
        show={activeModal === "signIn"}
        onClose={closeModal}
        modalPath={modalPath}
        setModalPath={setModalPath}
        openChooseSignUpModal={() => openModal("chooseSignUp")}
        openForgotPassword={() => openModal("forgotPassword")}
        openToken={() => openModal("TokenModal")}
      />
      <SignUp
        show={activeModal === "signUp"}
        onClose={closeModal}
        openMerchantSignUpModal={() => openModal("merchantSignUp")}
        openSignInModal={() => openModal("signIn")}
        openSignUpSuccessModal={() => openModal("signUpSuccess")}
      />
      <MerchantSignUp
        show={activeModal === "merchantSignUp"}
        openSignUpSuccessModal={() => openModal("signUpSuccess")}
        openSignInModal={() => openModal("signIn")}
        onClose={closeModal}
      />
      <SignUpSuccess
        show={activeModal === "signUpSuccess"}
        onClose={closeModal}
        openSignInModal={() => openModal("signIn")}
      />
      <ChooseSignUp
        show={activeModal === "chooseSignUp"}
        onClose={closeModal}
        openSignUpModal={() => openModal("signUp")}
        openSignInModal={() => openModal("signIn")}
        openMerchantSignUpModal={() => openModal("merchantSignUp")}
      />
      <ForgotPassword
        show={activeModal === "forgotPassword"}
        openSignInModal={() => openModal("signIn")}
        onClose={closeModal}
      />
      <CreateAddress
        show={activeModal === "createAddress"}
        onClose={closeModal}
        editFormObject={editFormObject}
        setEditFormObject={setEditFormObject}
      />
      <EditAddress
        show={activeModal === "editAddress"}
        onClose={closeModal}
        editFormObject={editFormObject}
        setEditFormObject={setEditFormObject}
      />

      <TokenModal
        show={activeModal === "TokenModal"}
        onClose={closeModal}
        openContactAdmin={() => openModal("forgotToken")}
      />

      <ForgotToken show={activeModal === "forgotToken"} onClose={closeModal} />
    </>
  );
};

export default Modals;
