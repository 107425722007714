import { createContext, useState } from "react";

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [activeModal, setActiveModal] = useState(null);
  const [modalPath, setModalPath] = useState(null);
  const [editFormObject, setEditFormObject] = useState('')
  const openModal = (name) => setActiveModal(name);
  const closeModal = () => setActiveModal(null);

  return (
    <ModalContext.Provider
      value={{ activeModal, openModal, closeModal, setModalPath, modalPath,editFormObject, setEditFormObject }}
    >
      {children}
    </ModalContext.Provider>
  );
};
