
import { createContext, useReducer, useEffect } from 'react';

export const BillsContext = createContext();

const billsReducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER_DETAILS':
      return { ...state, userDetails: action.payload };

    case 'SET_PLAN_DETAILS':
      return { ...state, planDetails: action.payload };

    case 'SET_TV_ADDON_DETAILS':
      return { ...state, tvAddonDetails: action.payload };

    case 'CLEAR_BILL':
      return {
        userDetails: null,
        planDetails: null,
        tvAddonDetails: null,
      };

    default:
      return state;
  }
};

export const BillsProvider = ({ children }) => {
  const initialState = {
    userDetails: null,
    planDetails: null,
    tvAddonDetails: null,
  };

  const [bill, dispatch] = useReducer(billsReducer, initialState, () => {
    const sessionData = sessionStorage.getItem('bill');
    return sessionData ? JSON.parse(sessionData) : initialState;
  });

  useEffect(() => {
    sessionStorage.setItem('bill', JSON.stringify(bill));
  }, [bill]);

  return (
    <BillsContext.Provider value={{ bill, dispatch }}>
      {children}
    </BillsContext.Provider>
  );
};
