import React, { useState } from "react";
import AnimatedButton from "../AnimatedButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "../../api/axios";
import { SyncLoader } from "react-spinners";
import { toast } from "sonner";
import Modal from "./Modal";
import { states, LGA } from "../../tools/states";
import { useAddress } from "../../hooks/useAddress";

const schema = yup.object().shape({
  state: yup.string().required("State is required"),
  city: yup.string().required("city is required"),
  delivery_type: yup.string().required("Delivery type is required"),
  address: yup
    .string()
    .required("Address is required")
    .max(255, "Address cannot be longer than 255 characters"),
});

function CreateAddress({ show, onClose, editFormObject, setEditFormObject }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: editFormObject,
  });
  const { createAddress, loading, editAddress } = useAddress();
  const onSubmit = async (data) => {
    const response = await createAddress(data);
    if (response) {
      onClose();
    } else {
      return;
    }
  };

  const [state, setState] = useState(states.slice());
  const [LocalGovernments, setLocalGovernments] = useState([]);

  const handleStateChange = (state) => {
    const selectedLGA = LGA[state.target.value];
    setLocalGovernments(selectedLGA);
  };

  return (
    <Modal show={show} onClose={onClose}>
      <h2 className="text-[20px] md:text-[24px] leading-[28px] md:leading-[33.6px] tracking-[-.2px] mb-[24px]">
        Create Address
      </h2>
      <form
        className="flex flex-col gap-[16px]"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col gap-[8px]">
          <label
            htmlFor="state"
            className="text-[14px] leading-[16.8px] md:text-[16px] md:leading-[19.2px] tracking-[-.2px] "
          >
            State of Residence
          </label>
          <select
            className={`h-[49px] bg-dGrey2 outline-none ${
              errors.state ? "border-[1px] border-red1" : "border-[0px]"
            } rounded-[12px] py-[10px] px-[16px] text-[14px] leading-[22.4px]`}
            id="state"
            {...register("state")}
            onChange={handleStateChange}
          >
            <option value="">Choose State</option>
            {state.map((individualState) => (
              <option key={individualState} value={individualState}>
                {individualState}
              </option>
            ))}
          </select>
          {errors.state && (
            <p className="text-xs text-red1">{errors.state?.message}</p>
          )}
        </div>

        <div className="flex flex-col gap-[8px]">
          <label
            htmlFor="city"
            className="text-[14px] leading-[16.8px] md:text-[16px] md:leading-[19.2px] tracking-[-.2px] "
          >
            City
          </label>
          <select
            className={`h-[49px] bg-dGrey2 outline-none ${
              errors.city ? "border-[1px] border-red1" : "border-[0px]"
            } rounded-[12px] py-[10px] px-[16px] text-[14px] leading-[22.4px]`}
            id="city"
            {...register("city")}
          >
            <option value="">Choose city</option>
            {LocalGovernments.map((LocalGovernment) => (
              <option key={LocalGovernment} value={LocalGovernment}>
                {LocalGovernment}
              </option>
            ))}
          </select>
          {errors.city && (
            <p className="text-xs text-red1">{errors.city?.message}</p>
          )}
        </div>
        <div className="flex flex-col gap-[8px]">
          <label
            htmlFor="address"
            className="text-[14px] leading-[16.8px] md:text-[16px] md:leading-[19.2px] tracking-[-.2px] "
          >
            Address
          </label>
          <input
            className={`h-[49px] bg-dGrey2 outline-none ${
              errors.address ? "border-[1px] border-red1" : "border-[0px]"
            } rounded-[12px] py-[10px] px-[16px] text-[14px] leading-[22.4px]`}
            type="text"
            id="address"
            placeholder="Enter address"
            {...register("address")}
          />
          {errors.address && (
            <p className="text-xs text-red1">{errors.address?.message}</p>
          )}
        </div>

        <div className="flex flex-col gap-[8px]">
          <label
            htmlFor="Delivery"
            className="text-[14px] leading-[16.8px] md:text-[16px] md:leading-[19.2px] tracking-[-.2px] "
          >
            Delivery type
          </label>
          <select
            className={`h-[49px] bg-dGrey2 outline-none ${
              errors.delivery_type ? "border-[1px] border-red1" : "border-[0px]"
            } rounded-[12px] py-[10px] px-[16px] text-[14px] leading-[22.4px]`}
            id="city"
            {...register("delivery_type")}
          >
            <option value="">Choose Delivery type</option>

            <option value={"home"}>Home Address</option>
            <option value={"office"}>Office Address</option>
            <option value={"cluster"}>Cluster Address</option>
          </select>
          {errors.delivery_type && (
            <p className="text-xs text-red1">{errors.delivery_type?.message}</p>
          )}
        </div>

        {/* submit */}
        <button type="submit">
          <AnimatedButton className="cursor-pointer text-[14px] lg:text-[18px] leading-[19.6px] lg:leading-[25.2px] tracking-[-.2px] w-full h-[48px] bg-darkGreen1 flex items-center justify-center text-[white] rounded-[8px]">
            {loading ? <SyncLoader color="#fff" size="12px" /> : "Submit"}
          </AnimatedButton>
        </button>
      </form>
    </Modal>
  );
}

export default CreateAddress;
