
import { createContext, useState } from "react";

const PaymentContext = createContext();

export const PaymentProvider = ({ children }) => {
  const [paymentMethod, setPaymentMethod] = useState("wallet");
  
  
  return(
    <PaymentContext.Provider value={{paymentMethod, setPaymentMethod}}>
        {children}
  </PaymentContext.Provider>
  ) 

 
};
export default PaymentContext;