import { Toaster as MyToast } from "sonner";
import { FaInfoCircle, FaRegCheckCircle } from "react-icons/fa";
import { MdError } from "react-icons/md";

const Toaster = () => {
  return (
    <MyToast
      visibleToasts={1}
      position="top-right"
      toastOptions={{
        duration: 2000,
        unstyled: true,
        classNames: {
          toast:
            "!bg-[white] !w-full !flex !items-center !p-3 !rounded-md !drop-shadow-md",
          // title: "text-darkGreen1",
          description: "text-darkGreen1",
          actionButton:
            "!bg-darkGreen1 !text-[white] !rounded-md !p-[6px] !text-sm !ml-auto !min-w-fit",
          error: "text-red1",
          success: "text-darkGreen1",
          info: "text-darkGreen1",
          warning: "text-darkGreen1",
        },
      }}
      icons={{
        success: <FaRegCheckCircle className="text-darkGreen1" />,
        info: <FaInfoCircle className="text-darkGreen1" />,
        // warning: <WarningIcon />,
        error: <MdError className="text-red1" />,
        // loading: <LoadingIcon />,
      }}
    />
  );
};

export default Toaster;
