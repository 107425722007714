import Modal from "./Modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import AnimatedButton from "../AnimatedButton";
import axios from "../../api/axios";
import { toast } from "sonner";
import { useState } from "react";
import { SyncLoader } from "react-spinners";
import { MdEmail } from "react-icons/md";
const schema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
});

function ForgotPassword({ show, onClose, openSignInModal }) {
  const [isLoading, setIsLoading] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    setIsLoading(true);

    const responseData = {
      email: data.email,
    };

    axios
      .post("/users/forgetpassword", responseData)
      .then((response) => {
        if (response.data.status !== "200") {
          toast.error(`${response.data.message}`);
          setIsLoading(false);
          return;
        }
        reset();
        setShowFeedback(true);
      })
      .catch((error) => {
        console.error(error);
        toast.error(`An error occurred. Please try again`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal show={show} onClose={onClose}>
      <div className="relative">
        {/* <button
          className="relative z-10"
          onClick={() => {
            setShowFeedback((prev) => !prev);
            console.log(showFeedback);
          }}
        >
          toggleShowAll
        </button> */}
        <div
          className={`transition-opacity duration-500 ease-in-out ${
            showFeedback
              ? "opacity-0 pointer-events-none"
              : "opacity-1 pointer-events-auto"
          }`}
        >
          <h2 className="text-[20px] md:text-[24px] leading-[28px] md:leading-[33.6px] tracking-[-.2px] mb-[24px]">
            Forgot Password
          </h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-[8px] mb-[15px] md:mb-[17px]">
              <label
                htmlFor="email"
                className="text-[14px] leading-[16.8px] md:text-[16px] md:leading-[19.2px] tracking-[-.2px] "
              >
                Email
              </label>
              <input
                className={`h-[49px] bg-dGrey2 outline-none ${
                  errors.email ? "border-[1px] border-red1" : "border-[0px]"
                } rounded-[12px] py-[10px] px-[16px] text-[14px] leading-[22.4px]`}
                type="email"
                id="email"
                placeholder="123@example.com"
                {...register("email")}
              />
              {errors.email && (
                <p className="text-xs text-red1">{errors.email?.message}</p>
              )}
            </div>
            <div className="flex justify-start mb-[15px] md:mb-[17px]">
              <div
                className="text-[14px] text-darkGreen3 leading-[22.4px] cursor-pointer"
                onClick={openSignInModal}
              >
                Remember Password? Sign In
              </div>
            </div>
            <button type="submit" className="w-full">
              <AnimatedButton
                className="cursor-pointer text-[14px] lg:text-[18px] leading-[19.6px] lg:leading-[25.2px] tracking-[-.2px] w-full h-[48px] bg-darkGreen1 flex items-center justify-center text-[white] rounded-[8px]"
                // onClick={onClose}
              >
                {isLoading ? (
                  <SyncLoader color="#fff" size="12px" />
                ) : (
                  "Send Email"
                )}
              </AnimatedButton>
            </button>
          </form>
        </div>

        {/* <div
          className={`flex flex-col  items-center !gap-4 !p-4  transition-all duration-500 ${
            showFeedback ? "opacity-1" : "opacity-0 hidden"
          }`}
        >
          <MdEmail className="size-14 text-darkGreen1" />
          <h1 className=" text-xl gilroy-regular">Password Reset Confirmed</h1>
          <p className="text-grey1 text-sm gilroy-regular text-center">
            Instructions to finish resetting your password have been sent to
            your email.
          </p>
        </div> */}

        <div
          className={`absolute inset-5 flex flex-col items-center !gap-4 !p-4 transition-opacity duration-500 ease-in-out z-0 ${
            showFeedback
              ? "opacity-1 pointer-events-auto"
              : "opacity-0 pointer-events-none"
          }`}
        >
          <MdEmail className="size-14 text-darkGreen1" />
          <h1 className="text-xl gilroy-regular">Password Reset Confirmed</h1>
          <p className="text-grey1 text-sm gilroy-regular text-center">
            Instructions to finish resetting your password have been sent to
            your email.
          </p>
        </div>
      </div>
    </Modal>
  );
}

export default ForgotPassword;
