import React from "react";
import Modal from "./Modal";
import { useState, useEffect } from "react";
import AnimatedButton from "../AnimatedButton";
import { IoPaperPlaneOutline } from "react-icons/io5";
import { SyncLoader } from "react-spinners";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import axiosInstance from "../../api/axios";
import { ApiRoutes } from "../../tools/apiRoutes";
import { toast } from "sonner";

const TokenModal = ({ show, onClose, openContactAdmin }) => {
  const [formFields, setFormFields] = useState(["", "", "", ""]);
  const [errors1, setErrors1] = useState({});
  const formRefs = useRef([]);
  const [loading, setLoading] = useState(false);
  const [cancelButton, setCancelButton] = useState(true);
  const { setAuth, setToken, token } = useAuth();
  const { verify2fa } = ApiRoutes;
  const navigate = useNavigate();
  // console.log("token, ", token);
  const handleSend = async (e) => {
    e.preventDefault();

    const error = {};
    formFields.forEach((field) => {
      if (field === "" || !field) {
        error[`error`] = "Fill all fields";
        setErrors1(error);
        return;
      }
    });

    try {
      setLoading(true);

      const response = await axiosInstance.post(verify2fa, {
        twofa_pin: Number(formFields.join("")),
      });

      if (response.data.status === "200") {
        setFormFields(["", "", "", ""]);
        const token = response.data.data.token;
        const user = response.data.data.rec;
        setAuth(user);
        setToken(token);

        toast.success("Login successful!");
        onClose();
      } else {
        toast.error(response.data.message);
        if (response.data.message.startsWith("Validation")) {
          Object.keys(response.data.errors).forEach((key) => {
            toast.error(`${response.data.errors[key][0]}`);
          });
        } else {
          toast.error(`${response.data.message}`);
        }
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Login failed");
    } finally {
      setLoading(false);
    }
  };

  const regex = /^\s*\d?\s*$/;
  const handleInputChange = (e, index) => {
    const { value } = e.target;
    if (!regex.test(value)) {
      return;
    }
    const newArr = [...formFields];
    newArr[index] = value.trim();
    setFormFields(newArr);
    setErrors1({});

    if (value && index < formFields.length - 1) {
      NextInput(index);
    }
  };

  const NextInput = (index) => {
    if (formRefs.current[index + 1]) {
      formRefs.current[index + 1].focus();
    }
  };

  const handleBackspaceAndEnter = (e, index) => {
    if (
      (e.key === "Backspace" || e.key === "Delete") &&
      !e.target.value &&
      index !== 0
    ) {
      formRefs.current[index - 1].focus();
    }
    if (e.key === "Enter" && e.target.value & (index < formFields.length - 1)) {
      formRefs.current[index + 1].focus();
    }
  };

  const handleCancelInput = () => {
    setFormFields(["", "", "", ""]);
    formRefs.current[0].focus();
  };

  return (
    <Modal show={show} onClose={onClose}>
      <div className="w-full  min-h-8 flex justify-center items-start  h-[100%] !mb-4  ">
        <div className="w-full  min-w-7 ">
          <IoPaperPlaneOutline className="!mb-4 size-6 lg:size-7" />
          <div className="heading !mb-1 text-!2xl lg:!text-3xl ">
            Verify 2FA
          </div>
          <div className="subHeading !mb-2 text-grey1 text-base gilroy-regular font-medium ">
            Type in your four digit pin
          </div>
          <form onSubmit={handleSend}>
            <div className="flex justify-between items-center  min-h-7 !py-2 !gap-x-1 !mb-4">
              {formFields.map((formField, index) => (
                <input
                  type="password"
                  className={`!h-20 border-2 w-[90px] max-sm:w-[75px] max-xs:w-[58px] rounded-md border-black text-3xl lg:pl-8 pl-7 max-xs:pl-5 `}
                  name={`input-${index}`}
                  maxLength={1}
                  value={formField}
                  key={index}
                  onChange={(event) => handleInputChange(event, index)}
                  onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                  ref={(el) => (formRefs.current[index] = el)}
                />
              ))}
            </div>
            {errors1 && (
              <div className="!-mt-2 mb-2">
                <small className="text-red-600">{errors1?.error}</small>
              </div>
            )}
            <div className="!max-w-[420px] flex justify-end items-center h-5 cursor-pointer !mb-8 !-mt-1">
              <AnimatedButton
                className={"text-grey1"}
                onClick={openContactAdmin}
              >
                Forgot otp?
              </AnimatedButton>
            </div>

            <div
              className={`flex items-center ${
                cancelButton ? "justify-between" : "justify-center"
              } !max-w-96 `}
            >
              <button
                type="button"
                className="!w-[calc(50%-10px)]"
                onClick={() => handleCancelInput()}
              >
                <AnimatedButton className="cursor-pointer !h-12 w-full text-black flex justify-center items-center gilroy-regular font-bold text-sm rounded-md border-2">
                  Cancel
                </AnimatedButton>
              </button>

              <button type="submit" className="!w-[calc(50%-10px)]">
                <AnimatedButton className="cursor-pointer !h-12 bg-darkGreen1 w-full text-white flex justify-center items-center gilroy-regular font-bold text-sm rounded-md">
                  {loading ? <SyncLoader color="#fff" size="12px" /> : "Submit"}
                </AnimatedButton>
              </button>

              {/* <AnimatedButton
                  className={
                    "bg-darkGreen1 !py-2 !px-2 text-white rounded-md cursor-pointer"
                  }
                  onClick={() => handleSend()}
                >
                  Send
                </AnimatedButton> */}
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export default TokenModal;
