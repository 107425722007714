// import Modal from "./Modal";
// import AnimatedButton from "../AnimatedButton";
// import Lottie from "lottie-react";
// import balls from "../../assets/images/sign-up-animation.json";

// const ChooseSignUp = ({
//   show,
//   onClose,
//   openSignUpModal,
//   openSignInModal,
//   openMerchantSignUpModal,
// }) => {
//   return (
//     <Modal show={show} onClose={onClose}>
//       <div className="flex flex-col items-center gap-[15px]">
//         <div className="size-[200px]">
//           <Lottie
//             animationData={balls}
//             background="transparent"
//             speed="1"
//             loop={true}
//             autoplay={true}
//           />
//         </div>
//         <div className="w-[60%] max-w-[250px] text-center text-[20px] lg:text-[24px] leading-[28px] lg:leading-[33.6px] tracking-[-.2px]">
//           Click your preferred sign up option
//         </div>
//         <AnimatedButton
//           className=" w-full cursor-pointer text-[14px] lg:text-[16px] leading-[19.6px] lg:leading-[22.4px] tracking-[-.2px] px-[32px] py-[14px] lg:py-[14px] lg:px-[10px] text-center bg-darkGreen1 text-[white] rounded-[8px] mb-[6px] inline-block"
//           onClick={openSignUpModal}
//         >
//           Sign Up As User
//         </AnimatedButton>
//         <AnimatedButton
//           className=" w-full cursor-pointer text-[14px] lg:text-[16px] leading-[19.6px] lg:leading-[22.4px] tracking-[-.2px] px-[32px] py-[14px] lg:py-[14px] lg:px-[10px] text-center bg-darkGreen1 text-[white] rounded-[8px] mb-[6px] inline-block"
//           onClick={openMerchantSignUpModal}
//         >
//           Sign Up As Merchant
//         </AnimatedButton>
//         <AnimatedButton
//           className=" w-full cursor-pointer text-[14px] lg:text-[16px] leading-[19.6px] lg:leading-[22.4px] tracking-[-.2px] px-[32px] py-[14px] lg:py-[14px] lg:px-[10px] text-center bg-[white] border border-darkGreen1 text-darkGreen1 rounded-[8px] mb-[6px] inline-block"
//           onClick={openSignInModal}
//         >
//           Back to Sign in
//         </AnimatedButton>
//       </div>
//     </Modal>
//   );
// };

// export default ChooseSignUp;

import Modal from "./Modal";
import AnimatedButton from "../AnimatedButton";
import Lottie from "lottie-react";
import balls from "../../assets/images/sign-up-animation.json";

const ChooseSignUp = ({
  show,
  onClose,
  openSignUpModal,
  openSignInModal,
  openMerchantSignUpModal,
}) => {
  return (
    <Modal show={show} onClose={onClose}>
      <div className="flex flex-col items-center gap-[15px]">
        <div className="size-[200px]">
          <Lottie
            animationData={balls}
            background="transparent"
            speed="1"
            loop={true}
            autoplay={true}
          />
        </div>
        <div className="w-[60%] max-w-[250px] text-center text-[20px] lg:text-[24px] leading-[28px] lg:leading-[33.6px] tracking-[-.2px]">
          Click sign up to get started
        </div>
        <AnimatedButton
          className=" w-full cursor-pointer text-[14px] lg:text-[16px] leading-[19.6px] lg:leading-[22.4px] tracking-[-.2px] px-[32px] py-[14px] lg:py-[14px] lg:px-[10px] text-center bg-darkGreen1 text-[white] rounded-[8px] mb-[6px] inline-block"
          onClick={openSignUpModal}
        >
          Sign Up 
        </AnimatedButton>
        
        <AnimatedButton
          className=" w-full cursor-pointer text-[14px] lg:text-[16px] leading-[19.6px] lg:leading-[22.4px] tracking-[-.2px] px-[32px] py-[14px] lg:py-[14px] lg:px-[10px] text-center bg-[white] border border-darkGreen1 text-darkGreen1 rounded-[8px] mb-[6px] inline-block"
          onClick={openSignInModal}
        >
          Back to Sign in
        </AnimatedButton>
      </div>
    </Modal>
  );
};

export default ChooseSignUp;

