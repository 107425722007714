import React from "react";
import Modal from "./Modal";
import { MdEmail } from "react-icons/md";
const ForgotToken = ({ show, onClose }) => {
  return (
    <Modal show={show} onClose={onClose}>
      <div
        className={`flex flex-col items-center !gap-4 !p-4 transition-opacity duration-500 ease-in-out z-0 `}
      >
        <MdEmail className="size-14 text-darkGreen1" />
        <h1 className="text-xl gilroy-regular">Contact the Admin</h1>
        <p className="text-grey1 text-sm gilroy-regular text-center">
          Please contact{" "}
          <span
            className="text-red-400 cursor-pointer"
            // onClick={() => {
            //   navigator.clipboard.writeText(this.state.textToCopy);
            // }}
          >
            {" "}
            elonMusk@gmail.com
          </span>{" "}
          for your token
        </p>
      </div>
    </Modal>
  );
};

export default ForgotToken;
