import { motion } from 'framer-motion'

export default function AnimatedButton({
  children,
  onClick,
  className,
  ...props
}) {
  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.9 }}
      className={className}
      onClick={onClick}
      {...props}
    >
      {children}
    </motion.div>
  )
}
