import "./App.css";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Routes from "./Routes";
import Modals from "./components/modals/Modals";
import { CartProvider } from "./context/CartContext";
import { AuthProvider } from "./context/AuthContext";
import { ModalProvider } from "./context/ModalContext";
import { WishlistProvider } from "./context/WishListContext";
import Toaster from "./components/Toaster";
import { BillsProvider } from "./context/BillsContext";
import { ProductProvider } from "./context/ProductContext";
import { PaymentProvider } from "./context/PaymentMethodsContext";
import { AddressProvider } from "./context/AddressContext";
import { RecieptProvider } from "./context/RecieptContext";
import { PhysicalAirtimeProvider } from "./context/PhysicalAirtimeContext";
function App() {
  return (
    <PhysicalAirtimeProvider>
      <AuthProvider>
        <PaymentProvider>
          <ProductProvider>
            <CartProvider>
              <RecieptProvider>
                <AddressProvider>
                  <WishlistProvider>
                    <BillsProvider>
                      <ModalProvider>
                        <div className="App">
                          <BrowserRouter>
                            <ScrollToTop />
                            <Navbar />
                            <Toaster />
                            <Modals />
                            <Routes />
                            <Footer />
                          </BrowserRouter>
                        </div>
                      </ModalProvider>
                    </BillsProvider>
                  </WishlistProvider>
                </AddressProvider>
              </RecieptProvider>
            </CartProvider>
          </ProductProvider>
        </PaymentProvider>
      </AuthProvider>
    </PhysicalAirtimeProvider>
  );
}

export default App;
