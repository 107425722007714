import { createContext, useEffect, useState } from "react";
import axios from "../api/axios";
import axiosInstance from "../api/axios";
import Cookies from "js-cookie";
import { toast } from "sonner";
import { useParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const [products, setProducts] = useState(() => {
    let productsInSession = sessionStorage.getItem("products");
    if (productsInSession) {
      // console.log("products from session storage", productsInSession);
      return JSON.parse(productsInSession);
    } else {
      // console.log("no products available");
      return [];
    }
  });

  const [loading, setLoading] = useState(true);
  const { setIsLoading } = useAuth();
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [category, setCategory] = useState("");
  const [token, setToken] = useState(Cookies.get("token"));
  const { product_id } = useParams();

  const userData = Cookies.get("UserData");
  const initialUserData = userData ? JSON.parse(userData) : null;
  const [productByCategoryDetails, setProductByCategoryDetails] = useState([]);

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/users/product/stocks");
      if (Number(response.data.status) === 200) {
        const Arrs = response.data.data;
        // console.log("products from sate directly", products);
        setProducts(Arrs);
        // console.log("products form context", Arrs);
        sessionStorage.setItem("products", JSON.stringify(response.data.data));
      } else {
        toast.error(`${response.data.message}`);
      }
    } catch (error) {
      console.error("Failed to fetch products:", error);
    } finally {
      setLoading(false);
    }
  };

  const getProductByCategoryAnProductName = (categoryId, productName) => {
    setIsLoading(true);
    const params = {
      category_id: categoryId,
      search: productName,
    };
    axiosInstance
      .get("/users/product/stocks/search", {
        headers: {
          logintoken: token,
        },
        params: params,
      })
      .then((response) => {
        const Arrs = response.data.data;
        setProducts(Arrs);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <ProductContext.Provider
      value={{
        products,
        loading,
        filteredProducts,
        category,
        getProductByCategoryAnProductName,
        setCategory,
        fetchProducts,
        setFilteredProducts,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};
