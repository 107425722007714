// const addToCart = async () => {
//   const cartArray = cart.map((cartItem) => ({
//     stock_id: cartItem.stock_id,
//     qty: cartItem.quantity,
//   }));
//   const cartObjectToSend = { stockId: cartArray };
//   setLoading(true);
//   await addMultipleToCart(cartObjectToSend);
//   setLoading(false);
// };

export const randomNumberGenerator = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const formatTimestamp = (createdAt) => {
  // Get the current date and time

  // Define the months array
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Format day with suffix (st, nd, rd, th)
  const day = createdAt.getDate();
  const daySuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // catches 11th - 19th
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Format the time in 24-hour format with two digits for minutes
  const hours = createdAt.getHours();
  const minutes = createdAt.getMinutes().toString().padStart(2, "0");
  const year = createdAt.getFullYear();

  // Construct the formatted date string
  const formattedDate = `${months[createdAt.getMonth()]} ${day}${daySuffix(
    day
  )} ${year}, ${hours}:${minutes}`;

  return formattedDate;
};

export function formatDbDate(dateString) {
  const date = new Date(dateString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return "Invalid Date"; // Handle invalid date inputs
  }

  // Options for formatting (e.g., Month Day, Year format)
  const options = {
    year: "numeric",
    month: "long", // 'long' gives full month name like "September"
    day: "numeric",
    hour: "2-digit", // Adds hours to the format
    minute: "2-digit", // Adds minutes to the format
    second: "2-digit", // Adds seconds to the format
    hour12: true, // Shows time in 12-hour format (AM/PM)
  };

  // Format the date as 'Month Day, Year, Time AM/PM'
  return date.toLocaleString(undefined, options);
}

export function capitializeFirstLetter(letter) {
  if (typeof letter !== "string") {
    return letter;
  }
  return letter.charAt(0).toLocaleUpperCase() + letter.slice(1);
}
