import React from "react";
import { useSearchParams } from "react-router-dom";
import { useProduct } from "../../hooks/useProduct";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import ArrowRight from "../../assets/images/arrow-right.png";
import SearchProducts from "./SearchProducts";
import NotFound from "../../components/NotFound";
import { useAuth } from "../../hooks/useAuth";
const WordSearch = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const productName = searchParams.get("q");

  // console.log("searchParams1", productName);
  const { getProductByCategoryAnProductName, products } = useProduct();

  const { isLoading, setIsLoading } = useAuth();

  // useEffect(() => {
  //   const getData = async () => {
  //     let response = await getProductByCategoryAnProductName(
  //       null,
  //       searchParams.q
  //     );
  //   };
  // }, [searchParams.q]);
  return (
    <div className="w-[90%] max-w-screen-xl mx-auto mb-[60px] lg:mb-[120px]">
      <div className="flex gap-[8px] items-center mt-[32px] lg:mt-[60px] mb-[24px] lg:[32px]">
        <div className="text-[12px] leading-[19.2px] md:text-[16px] md:leading-[25.6px] text-darkGreen3">
          <Link to="/">Home</Link>
        </div>
        <div className="size-[12px]">
          <img src={ArrowRight} alt="" />
        </div>
        <div className="text-[12px] leading-[19.2px] md:text-[16px] md:leading-[25.6px] text-darkGreen3 capitalize">
          All Products
        </div>
      </div>

      <div className="flex justify-between flex-wrap gap-[24px]">
        <div className="flex flex-col gap-[4px]">
          <div className="text-[18px] leading-[25.2px] tracking-[-.2px] md:text-[24px] md:leading-[33.6px] capitalize">
            Showing {productName} in all categories
          </div>
          {/* <div className="text-grey1 text-[12px] leading-[19.2px] md:text-[16px] md:leading-[25.6px]">
            Showing 1 - {filteredProducts.lenght} Products
          
          </div> */}
        </div>
        {/* <div className="flex h-[32px] md:h-[44px] md:gap-[24px] items-center w-full md:w-auto justify-between">
          <div className="text-grey1 text-[12px] leading-[19.2px] md:text-[16px] md:leading-[25.6px]">
            Sort By:
          </div>
          <div className="px-[10px] h-full flex md:px-[16px] border border-dGrey1 rounded-[8px]">
            <select className="text-[12px] leading-[19.2px] md:text-[14px] md:leading-[22.4px]">
              <option value="option1">Relevant Products</option>
            </select>
          </div>
        </div> */}
      </div>

      {isLoading ? (
        <div className="flex gap-[40px] mt-[40px] justify-center items-center">
          <div className="pt-[100px] pb-[100px] justify-center items-center">
            <p className="items-center">
              <span className="loading loading-dots loading-lg"></span>
            </p>
          </div>
        </div>
      ) : (
        <>
          {products && products.length > 0 ? (
            <div className="flex gap-[40px] mt-[40px] justify-center_">
              <SearchProducts filteredProducts={products} />
            </div>
          ) : (
            <div className="items-center justify-between">
              <NotFound />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default WordSearch;
