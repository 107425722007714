import Facebook from "../../assets/images/facebook.png";
import Modal from "./Modal";
import AnimatedButton from "../AnimatedButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useState } from "react";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { toast } from "sonner";
import { SyncLoader } from "react-spinners";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import { MdCancel } from "react-icons/md";

// import { useCart } from "../../hooks/useCart";
const schema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
});

export default function SignIn({
  show,
  onClose,
  openChooseSignUpModal,
  openForgotPassword,
  modalPath,
  setModalPath,
  openToken,
}) {
  const [viewPassword, setViewPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { setAuth, setToken } = useAuth();

  const navigate = useNavigate();

  // const {addMultipleToCart,cart,setCart} = useCart();

  // const fetchData = async ()=>{
  //   if (cart && cart.length > 0) {
  //     setIsLoading(true);
  //     try {
  //       const cartArray = cart.map((cartItem) => ({
  //         stock_id: cartItem.stock_id,
  //         qty: cartItem.quantity,
  //       }));

  //       const cartObjectToSend = { stockId: cartArray };
  //       console.log('cartObject', cartObjectToSend);

  //       await addMultipleToCart(cartObjectToSend);
  //       // setCartUpdateStatus(true);
  //       console.log('no cart available')
  //     } catch (error) {
  //       console.error("Error fetching cart:", error);
  //       // setCartUpdateStatus(false);
  //       // console.log('status false');
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   } else {

  //     const storedCart = localStorage.getItem("joatech_cart");
  //     if (storedCart) {
  //       const storedCartParsed = JSON.parse(storedCart);
  //       setCart(storedCartParsed);
  //       console.log('no cart available')
  //     } else {
  //       setCart([]);
  //     }
  //   }
  // }

  const toggleViewPassword = () => {
    setViewPassword((prev) => !prev);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);

      const response = await axios.post("/users/login", data);
      console.log("response", response);
      if (response.data.status === "200") {
        const token = response.data.data.token;
        const user = response.data.data.rec;
        setAuth(user);
        setToken(token);

        toast.success("Login successful!");

        if (modalPath) {
          navigate(modalPath);
          setModalPath(null);
        }
        reset();
        onClose();
      } else if (
        response.data.message === "2FA PIN required. Please verify your PIN."
      ) {
        const token = response.data.token;
        console.log("goingn to token");
        setToken(token);
        openToken();
      } else {
        toast.error(response.data.message);
        if (response.data.message.startsWith("Validation")) {
          Object.keys(response.data.errors).forEach((key) => {
            toast.error(`${response.data.errors[key][0]}`);
          });
        } else {
          toast.error(`${response.data.message}`);
        }
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Login failed");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal show={show} onClose={onClose}>
      <div className="flex justify-end text-darkGreen1">
        <MdCancel className="w-20 h-20" onClick={onClose} />
      </div>

      <h2 className="text-[20px] md:text-[24px] leading-[28px] md:leading-[33.6px] tracking-[-.2px] mb-[24px]">
        Sign In
      </h2>
      <form
        className="flex flex-col gap-[16px]"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col gap-[8px]">
          <label
            htmlFor="email"
            className="text-[14px] leading-[16.8px] md:text-[16px] md:leading-[19.2px] tracking-[-.2px] "
          >
            Email
          </label>
          <input
            className={`h-[49px] bg-dGrey2 outline-none ${
              errors.email ? "border-[1px] border-red1" : "border-[0px]"
            } rounded-[12px] py-[10px] px-[16px] text-[14px] leading-[22.4px]`}
            type="email"
            id="email"
            placeholder="123@example.com"
            {...register("email")}
          />
          {errors.email && (
            <p className="text-xs text-red1">{errors.email?.message}</p>
          )}
        </div>
        <div className="flex flex-col gap-[8px]">
          <label
            htmlFor="password"
            className="text-[14px] leading-[16.8px] md:text-[16px] md:leading-[19.2px] tracking-[-.2px] "
          >
            Password
          </label>
          <div
            className={`flex justify-between items-center h-[49px] bg-dGrey2 outline-none ${
              errors.password ? "border-[1px] border-red1" : "border-[0px]"
            } rounded-[12px] py-[10px] px-[16px] text-[14px] leading-[22.4px]`}
          >
            <input
              className="customInput bg-dGrey2 outline-none border-[0px] w-[75%]"
              type={`${viewPassword ? "text" : "password"}`}
              id="password"
              name="password"
              placeholder="Enter your password"
              {...register("password")}
            />
            {viewPassword ? (
              <IoMdEye
                onClick={toggleViewPassword}
                className="cursor-pointer h-full w-fit"
              />
            ) : (
              <IoMdEyeOff
                onClick={toggleViewPassword}
                className="cursor-pointer h-full w-fit"
              />
            )}
          </div>
          {errors.password && (
            <p className="text-xs text-red1">{errors.password?.message}</p>
          )}
        </div>
        <div className="flex justify-between mb-[15px] md:mb-[17px]">
          <div
            className="text-[14px] text-darkGreen3 leading-[22.4px] cursor-pointer"
            onClick={openChooseSignUpModal}
          >
            Sign Up
          </div>
          <div
            className="text-[14px] text-grey1 leading-[22.4px] cursor-pointer"
            onClick={openForgotPassword}
          >
            Forgot Password?
          </div>
        </div>
        <button type="submit">
          <AnimatedButton className="cursor-pointer text-[14px] lg:text-[18px] leading-[19.6px] lg:leading-[25.2px] tracking-[-.2px] w-full h-[48px] bg-darkGreen1 flex items-center justify-center text-[white] rounded-[8px] !mb-4">
            {isLoading ? <SyncLoader color="#fff" size="12px" /> : "Sign In"}
          </AnimatedButton>
        </button>
        {/* <div className="h-[39px] flex justify-center items-center relative">
          <div className="absolute w-full h-[2px] bg-dGrey1 z-10"></div>
          <div className="text-grey1 text-[12px] md:text-[14px] leading-[19.2px] md:leading-[22.4px] z-20 p-[10px] bg-[white]">
            Or using other method
          </div>
        </div>
        <div className="h-[48px] border border-dGrey1 rounded-[8px] flex justify-center items-center gap-[8px] cursor-pointer">
          <div className="size-[14px]">
            <img src={Facebook} alt="" className="size-full object-contain" />
          </div>
          <div className="text-[14px] leading-[22.4px] md:text-[16px] md:leading-[25.6px]">
            Sign In with Facebook
          </div>
        </div> */}
      </form>
    </Modal>
  );
}
