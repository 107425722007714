import tickCircle from "../../assets/images/tick-circle.png";
import Modal from "./Modal";
import AnimatedButton from "../AnimatedButton";

const SignUpSuccess = ({ show, onClose, openSignInModal }) => {
  return (
    <Modal show={show} onClose={onClose}>
      <div className="flex flex-col items-center gap-[40px]">
        <div className="size-[120px]">
          <img src={tickCircle} alt="" className="size-full object-contain" />
        </div>
        <div className="w-[60%] max-w-[250px] text-center text-[20px] lg:text-[24px] leading-[28px] lg:leading-[33.6px] tracking-[-.2px]">
          Account & Wallet Created Successfully!
        </div>
        <AnimatedButton
          className=" w-full cursor-pointer text-[14px] lg:text-[16px] leading-[19.6px] lg:leading-[22.4px] tracking-[-.2px] px-[32px] py-[14px] lg:py-[14px] lg:px-[10px] text-center bg-darkGreen1 text-[white] rounded-[8px] mb-[6px] inline-block"
          onClick={openSignInModal}
        >
          Sign In
        </AnimatedButton>
      </div>
    </Modal>
  );
};

export default SignUpSuccess;
