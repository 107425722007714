import Modal from './Modal'
import AnimatedButton from '../AnimatedButton'

export default function MerchantSignUp({
  show,
  onClose,
  openSignUpSuccessModal,
  openSignInModal,
}) {

  return (
    <Modal show={show} onClose={onClose}>
      <h2 className=" text-darkGreen1 text-[20px] md:text-[24px] leading-[28px] md:leading-[33.6px] tracking-[-.2px] mb-[24px]">
        Merchant Sign Up
      </h2>
      <form className="flex flex-col gap-[16px]">
        <div className="flex flex-col gap-[8px]">
          <label
            htmlFor="name"
            className="text-[14px] leading-[16.8px] md:text-[16px] md:leading-[19.2px] tracking-[-.2px] "
          >
            Business Name
          </label>
          <input
            className="h-[49px] bg-dGrey2 outline-none border-[0px] rounded-[12px] py-[10px] px-[16px] text-[14px] leading-[22.4px]"
            type="text"
            id="name"
            name="business_name"
            placeholder="Enter your business name"
          />
        </div>
        <div className="flex flex-col lg:flex-row gap-[8px] lg:gap-[11px]">
          <div className="flex flex-col gap-[8px] lg:w-[40%]">
            <label
              htmlFor="contact"
              className="text-[14px] leading-[16.8px] md:text-[16px] md:leading-[19.2px] tracking-[-.2px] "
            >
              Phone Number
            </label>
            <input
              className="h-[49px] bg-dGrey2 outline-none border-[0px] rounded-[12px] py-[10px] px-[16px] text-[14px] leading-[22.4px]"
              type="phone"
              id="contact"
              placeholder="Enter your phone"
            />
          </div>
          <div className="flex flex-col gap-[8px] lg:w-[57%]">
            <label
              htmlFor="email"
              className="text-[14px] leading-[16.8px] md:text-[16px] md:leading-[19.2px] tracking-[-.2px] "
            >
              Email
            </label>
            <input
              className="h-[49px] bg-dGrey2 outline-none border-[0px] rounded-[12px] py-[10px] px-[16px] text-[14px] leading-[22.4px]"
              type="email"
              id="email"
              name="email"
              placeholder="123@example.com"
            />
          </div>
        </div>
        <div className="flex flex-col gap-[8px]">
          <label
            htmlFor="password"
            className="text-[14px] leading-[16.8px] md:text-[16px] md:leading-[19.2px] tracking-[-.2px] "
          >
            Password
          </label>
          <input
            className="h-[49px] bg-dGrey2 outline-none border-[0px] rounded-[12px] py-[10px] px-[16px] text-[14px] leading-[22.4px]"
            type="password"
            id="password"
            name="password"
            placeholder="Enter your password"
          />
        </div>
        <div className="flex flex-col gap-[8px]">
          <label
            htmlFor="category"
            className="text-[14px] leading-[16.8px] md:text-[16px] md:leading-[19.2px] tracking-[-.2px] "
          >
            Category
          </label>
          <select
            className="h-[49px] bg-dGrey2 outline-none border-[0px] rounded-[12px] py-[10px] px-[16px] text-[14px] leading-[22.4px]"
            id="category"
          >
            <option value="option1">...Fashion/Clothings.</option>
            <option value="option2">Electronics</option>
          </select>
        </div>
        <div className="flex flex-col gap-[8px]">
          <label
            htmlFor="address"
            className="text-[14px] leading-[16.8px] md:text-[16px] md:leading-[19.2px] tracking-[-.2px] "
          >
            Address
          </label>
          <input
            className="h-[49px] bg-dGrey2 outline-none border-[0px] rounded-[12px] py-[10px] px-[16px] text-[14px] leading-[22.4px]"
            type="text"
            id="address"
            name="address"
            placeholder="12, Ogunike Ikeja "
          />
          <div className="flex gap-[12px]">
            <input
              className="h-[49px] w-[38.5%] bg-dGrey2 outline-none border-[0px] rounded-[12px] py-[10px] px-[16px] text-[14px] leading-[22.4px]"
              type="text"
              id="city"
              name="city"
              placeholder="City"
            />
            <input
              className="h-[49px] w-[56%] bg-dGrey2 outline-none border-[0px] rounded-[12px] py-[10px] px-[16px] text-[14px] leading-[22.4px]"
              type="text"
              id="zip"
              name="zip_code"
              placeholder="Zip Code"
            />
          </div>
        </div>
        <div className="flex flex-col gap-[8px]">
          <label
            htmlFor="info"
            className="text-[14px] leading-[16.8px] md:text-[16px] md:leading-[19.2px] tracking-[-.2px] "
          >
            Info
          </label>
          <input
            className="h-[49px] bg-dGrey2 outline-none border-[0px] rounded-[12px] py-[10px] px-[16px] text-[14px] leading-[22.4px]"
            type="text"
            id="info"
            name="info"
          />
        </div>
        <div className="flex justify-between">
          <div
            className="text-[14px] text-darkGreen3 leading-[22.4px] cursor-pointer"
            onClick={openSignInModal}
          >
            Sign In
          </div>
          <div className="text-[14px] text-grey1 leading-[22.4px] cursor-pointer">
            Getting Trouble?
          </div>
        </div>
        <AnimatedButton
          className="cursor-pointer text-[14px] lg:text-[18px] leading-[19.6px] lg:leading-[25.2px] tracking-[-.2px] w-full h-[48px] bg-darkGreen1 flex items-center justify-center text-[white] rounded-[8px] mb-[24px] md:mb-[22px]"
          onClick={openSignUpSuccessModal}
        >
          Sign Up
        </AnimatedButton>
      </form>
    </Modal>
  )
}
