// export const formatNumber = (number) => {
//   // console.log("numberrrr", number);
//   if (typeof number === "string") {
//     number = Number(number);
//   }
//   if (number >= 1000000) {
//     return (number / 1000000).toFixed(2) + "m";
//   } else if (number >= 1000) {
//     return (number / 1000).toFixed(2) + "k";
//   } else {
//     return number.toString();
//   }
// };



export const formatNumber = (number) => {
  // Check if number is undefined, null, or not a valid number
  if (number == null || isNaN(number)) {
    return "0"; // or handle this case appropriately
  }

  // If the number is a string, convert it to a number
  if (typeof number === "string") {
    number = Number(number);
  }

  // Handle numbers greater than or equal to 1,000,000 (convert to "m" for million)
  if (number >= 1000000) {
    return (number / 1000000).toFixed(2) + "m";
  }
  // Handle numbers greater than or equal to 1,000 (convert to "k" for thousand)
  else if (number >= 1000) {
    return (number / 1000).toFixed(2) + "k";
  }
  // For smaller numbers, just return the number as a string
  else {
    return number.toString();
  }
};


export const formatToLocale = (number) => {
  if (typeof number === "string") {
    number = number.replace(/,/g, "");
  }

  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
};

export const RemoveCommaFromNumber = (number) => {
  if (typeof number === "string") {
    number = number.replace(/,/g, "");

    return Number(number);
  } else {
    return number;
  }
};
