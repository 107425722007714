// AuthContext.js
import { createContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { toast } from "sonner";
import axiosInstance from "../api/axios";

const AuthContext = createContext();

let logoutFunction = null;

export const AuthProvider = ({ children }) => {
  const userData = Cookies.get("UserData");
  const initialUserData = userData ? JSON.parse(userData) : null;
  const [auth, setAuth] = useState(initialUserData);
  const [commissionBalance, setCommissionBalance] = useState("");
  const [token, setToken] = useState(Cookies.get("token"));
  const [isLoading, setIsLoading] = useState(false);
  const [itIsLoading, setItIsLoading] = useState(false);
  const [twoFaEnabled, setTwoFaEnabled] = useState(false);
  // const {setCartUpdateStatus} = useCart();
  useEffect(() => {
    Cookies.set("UserData", JSON.stringify(auth), { expires: 7 });
  }, [auth]);

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common["logintoken"] = token;
      Cookies.set("token", token, { expires: 1 / 24 });
    } else {
      delete axios.defaults.headers.common["logintoken"];
      Cookies.remove("token");
    }
  }, [token]);

  const logout = () => {
    Cookies.remove("token");
    setAuth(null);
    localStorage.removeItem("joatech_cart");
    sessionStorage.clear();
    // setCartUpdateStatus(false)
    toast.success("logout successful!");
  };

  const getBalance = () => {
    setIsLoading(true);
    const balanceData = {
      user_id: auth.id.toString(),
    };
    axiosInstance
      .post("/users/balance", balanceData, {
        headers: {
          logintoken: token,
        },
      })
      .then((response) => {
        if (response.data.original?.status === "200") {
          setAuth({ ...auth, wallet_balance: response.data.original?.data });
        } else if (response.data?.status === "200") {
          setAuth({ ...auth, wallet_balance: response.data.data });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getCommissionBalance = () => {
    setItIsLoading(true);
    const balanceData = {
      user_id: auth.id.toString(),
    };
    axiosInstance
      .post("/users/commissionbalance", balanceData, {
        headers: {
          logintoken: token,
        },
      })
      .then((response) => {
        if (response.data.status === "200") {
          setCommissionBalance(response.data.data);
        } else if (response.data?.status === "200") {
          setCommissionBalance(response.data.data);
        }
      })
      .finally(() => {
        setItIsLoading(false);
      });
  };

  // Set the logout function for external use
  logoutFunction = () => {
    Cookies.remove("token");
    setAuth(null);
    localStorage.removeItem("joatech_cart");
    sessionStorage.clear();
    // setCartUpdateStatus(false)
    toast.error("Session Expired.. Please Log In Again");
  };
  const [flashProds, setFlashProds] = useState([]);
  const [categories, setCategories] = useState([]);
  const [newProds, setNewProds] = useState([]);
  const [populars, setPopulars] = useState([]);
  const [hotdeals, setHotdeals] = useState([]);

  function getRandomThreeDigitNumber() {
    // return Math.floor(Math.random() * 900) + 100; // 3 digits
    return Math.floor(Math.random() * 900) + 100; // 2 digits
  }

  function formatString(str) {
    if (!str) return str;
    return str
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(" ");
  }

  const fetchCategories = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get("/users/categories");
      if (Number(response.data.status) === 200) {
        // console.log(response.data.data);
        // setCategories(response.data.data);
        const cats = response.data.data;

        const filteredCategories = cats.map((category) => ({
          id: category.id,
          name: category.name,
          status: category.status,
          sub_categories: category.sub_categories,
        }));
        const updatedCategories = [
          { id: 0, name: "All Category", status: "active", sub_categories: [] },
          ...filteredCategories,
        ];

        // console.log("updatedCategories");
        // console.log(updatedCategories);
        setCategories(updatedCategories);
      } else {
        toast.error(`${response.data.message}`);
      }
    } catch (error) {
      console.error("Failed to fetch products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFlashSales = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        "/users/product/stocks/flashsales"
      );
      if (Number(response.data.status) === 200) {
        // console.log(response.data.data);
        // setFlashProds(response.data.data);

        const transformedData = response.data.data
          .slice(0, 5)
          .map((product) => ({
            ...product, // Spread the existing properties
            stock_id: product.id, // Add stock_id property
            id: undefined, // Remove the id property
          }));
        setFlashProds(transformedData);
      } else {
        toast.error(`${response.data.message}`);
      }
    } catch (error) {
      console.error("Failed to fetch products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchNewProds = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        "/users/product/stocks/new-arrivals"
      );
      if (Number(response.data.status) === 200) {
        const transformedData = response.data.data.data
          .slice(0, 5)
          .map((product) => ({
            ...product, // Spread the existing properties
            stock_id: product.id, // Add stock_id property
            id: undefined, // Remove the id property
          }));
        setNewProds(transformedData);
      } else {
        toast.error(`${response.data.message}`);
      }
    } catch (error) {
      console.error("Failed to fetch products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPopularProds = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get("/users/product/stocks/popular");
      if (Number(response.data.status) === 200) {
        const transformedData = response.data.data
          .slice(0, 5)
          .map((product) => ({
            ...product, // Spread the existing properties
            stock_id: product.id, // Add stock_id property
            id: undefined, // Remove the id property
          }));
        setPopulars(transformedData);
      } else {
        console.error(`${response.data.message}`);
      }
    } catch (error) {
      console.error("Failed to fetch products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchHotDeals = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        "/users/product/stocks/hotdeals"
      );
      if (Number(response.data.status) === 200) {
        const transformedData = response.data.data
          .slice(0, 5)
          .map((product) => ({
            ...product,
            stock_id: product.id,
            id: undefined,
          }));

        setHotdeals(transformedData);
      } else {
        toast.error(`${response.data.message}`);
      }
    } catch (error) {
      console.error("Failed to fetch products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchNewProds();
    fetchFlashSales();
    fetchPopularProds();
    fetchHotDeals();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        logout,
        setToken,
        getBalance,
        isLoading,
        getCommissionBalance,
        itIsLoading,
        commissionBalance,
        setIsLoading,
        categories,
        newProds,
        flashProds,
        populars,
        hotdeals,
        getRandomThreeDigitNumber,
        formatString,
        twoFaEnabled,
        setTwoFaEnabled,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Utility function to log out the user
export const logoutUser = () => {
  if (logoutFunction) {
    logoutFunction();
  }
};

export default AuthContext;
