import { createContext, useReducer, useEffect } from 'react';

export const WishlistContext = createContext();

const wishlistReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_TO_WISHLIST':
      // eslint-disable-next-line no-case-declarations
      const { product } = action.payload;
      if (state.some(item => item.stock_id === product.stock_id)) {
        return state;
      }
      return [...state, product];

    case 'REMOVE_FROM_WISHLIST':
      return state.filter(item => item.stock_id !== action.payload.productId);

    case 'CLEAR_WISHLIST':
      return [];

    default:
      return state;
  }
};

export const WishlistProvider = ({ children }) => {
  const [wishlist, dispatcher] = useReducer(wishlistReducer, [], () => {
    const localData = localStorage.getItem('Joatech_wishlist');
    return localData ? JSON.parse(localData) : [];
  });

  useEffect(() => {
    localStorage.setItem('Joatech_wishlist', JSON.stringify(wishlist));
  }, [wishlist]);

  return (
    <WishlistContext.Provider value={{ wishlist, dispatcher }}>
      {children}
    </WishlistContext.Provider>
  );
};
