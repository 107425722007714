import { createContext, useReducer, useEffect, useState } from "react";
import { ApiRoutes } from "../tools/apiRoutes";
import { useAuth } from "../hooks/useAuth";
import axiosInstance from "../api/axios";
import { toast } from "sonner";
import { randomNumberGenerator } from "../tools/FunctionsIMayUse";
export const AddressContext = createContext();

export const AddressProvider = ({ children }) => {
  const [defaultAddress, setDefaultAddress] = useState({});
  const [addresses, setAddressess] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addressChangeSwitch, setAddressChangeSwitch] = useState("");
  const { auth } = useAuth();
  const findDefaultAddress = (data) => {
    return data.find((address) => address.default === "1") || data[0] || {};
  };

  const createAddress = async (AddressBody) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(
        ApiRoutes.createAddress,
        AddressBody
      );
      if (Number(response.data.status) === 200) {
        const addressRefresher = randomNumberGenerator(1, 10);
        setAddressChangeSwitch(addressRefresher);
        toast.success("Address was created successfully");
        return true;
      } else {
        toast.error("Something went wrong, try again later");
        console.error(response);
        return false;
      }
    } catch (e) {
      toast.error("Couldn't create address, try again later");
      console.error("couldn't create address", e);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const editAddress = async (AddressBody) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(
        ApiRoutes.editAddress,
        AddressBody
      );
      if (Number(response.data.status) === 200) {
        const addressRefresher = randomNumberGenerator(1, 10);
        setAddressChangeSwitch(addressRefresher);
        toast.success("Address was Updated successfully");
      } else {
        toast.error("Could not update Address, try again later");
        console.error(response);
      }
    } catch (e) {
      toast.error("Something went wrong, try again later");
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const deleteAddress = async (AddressBody) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(
        ApiRoutes.deleteAddress,
        AddressBody
      );
      if (Number(response.data.status) === 200) {
        const addressRefresher = randomNumberGenerator(1, 10);
        setAddressChangeSwitch(addressRefresher);
        toast.success("Address was deleted successfully");
        return true;
      } else {
        toast.error("Could not delete address, try again later");
        console.error(response);
        return false;
      }
    } catch (e) {
      toast.error("Something went wrong, try again later");
      console.error(e);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const ChooseDefault = async (addressBody) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(ApiRoutes.makeAddressDefault, {
        address_id: addressBody,
      });
      if (Number(response.data.status) === 200) {
        const addressRefresher = randomNumberGenerator(1, 10);
        setAddressChangeSwitch(addressRefresher);
      } else {
        toast.error("Failed to make default");
        console.error(response);
      }
    } catch (e) {
      toast.error("Something went wrong, try again later");
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getAddress = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(ApiRoutes.getAddress);
        if (Number(response.data.status) === 200) {
          const defaultAdd = findDefaultAddress(response.data.data);
          if (defaultAdd) {
            setDefaultAddress(defaultAdd);
          }
          setAddressess(response.data.data);
        } else {
          console.error("Couldn't Retrieve address");
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };
    if (auth) {
      getAddress();
    }
  }, [auth, addressChangeSwitch]);

  return (
    <AddressContext.Provider
      value={{
        defaultAddress,
        addresses,
        loading,
        createAddress,
        deleteAddress,
        ChooseDefault,
        editAddress,
      }}
    >
      {children}
    </AddressContext.Provider>
  );
};
