import { Routes as Router, Route, Navigate } from "react-router-dom";
import { Suspense, lazy } from "react";
import { useAuth } from "./hooks/useAuth";
import { toast } from "sonner";
import TicketDetails from "./pages/Settings/MessageTicketDetails";
import WordSearch from "./pages/Search/WordSearch";

const Home = lazy(() => import("./pages/Home/index"));
const Search = lazy(() => import("./pages/Search/index"));
const Product = lazy(() => import("./pages/Product/index"));
const ShoppingCart = lazy(() => import("./pages/ShoppingCart/index"));
const Extra = lazy(() => import("./pages/Extra/Extra"));
const ExtraFailed = lazy(() => import("./pages/Extra/ExtraFailed"));
const Checkout = lazy(() => import("./pages/Checkout/index"));
const Payment = lazy(() => import("./pages/Payment/index"));
const PaymentNext = lazy(() => import("./pages/PaymentNext/index"));
const Airtime = lazy(() => import("./pages/Airtime/index"));
const Data = lazy(() => import("./pages/Data/index"));
const Bills = lazy(() => import("./pages/Bills/index"));
const PayTVIndex = lazy(() => import("./pages/Bills/TV/index"));
const SettingsLayout = lazy(() => import("./layout/SettingsLayout"));
const OverviewFilter = lazy(() => import("./pages/Settings/OverviewFilter"));
const PaymentFilter = lazy(() => import("./pages/Settings/PaymentFilter"));
const Message = lazy(() => import("./pages/Settings/Message"));
const Wishlist = lazy(() => import("./pages/Settings/Wishlist"));
const Coupon = lazy(() => import("./pages/Settings/Coupon"));
const History = lazy(() => import("./pages/Settings/History"));
const Orders = lazy(() => import("./pages/Settings/Orders"));
const OrderPhysical = lazy(() => import("./pages/Settings/OrderPhysical"));
const PackageTracking = lazy(() => import("./pages/Settings/PackageTracking"));
const ChangePassword = lazy(() => import("./pages/Settings/ChangePassword"));
const ShippingAddress = lazy(() => import("./pages/Settings/ShippingAddress"));
const RecieptsIndex = lazy(() => import("./pages/Reciepts/index"));
const BillsHistory = lazy(() => import("./pages/History/index"));
const Disco = lazy(() => import("./pages/Disco/Disco"));
const PhysicalProduct = lazy(() => import("./pages/PhysicalAirtime/index"));
const OrdersParent = lazy(() => import("./pages/Settings/OrdersParent"));
const GlobalSearch = lazy(() => import("./pages/Search/WordSearch"));
const TrackOrders = lazy(() => import("./pages/Settings/TrackOrders"));
const WalletHistory = lazy(() => import("./pages/Settings/WalletHistory"));
const TwoFactor = lazy(() => import("./pages/Settings/TowFactor"));
const PhysicalProduct2 = lazy(() =>
  import("./pages/PhysicalAirtime/PhysicalProduct2")
);
const VendorStore = lazy(() => import("./pages/VendorStore/index"));
const Test = lazy(() => import("./pages/test/Test"));

const TermsAndConditions = lazy(() =>
  import("./pages/TermsAndConditions/index")
);
const MessageTicket = lazy(() => import("./pages/Settings/MessageTicket"));
const MessageTicketList = lazy(() =>
  import("./pages/Settings/MessageTicketList")
);
const MessageTicketDetails = lazy(() =>
  import("./pages/Settings/MessageTicketDetails")
);
const MessageTicketUpdate = lazy(() =>
  import("./pages/Settings/MessageTicketUpdate")
);
const CreateMessageTicket = lazy(() =>
  import("./pages/Settings/CreateMessageTicket")
);
const AddMessageToTicket = lazy(() =>
  import("./pages/Settings/AddMessageToTicket")
);

const ProtectedRoute = ({ children }) => {
  const { auth } = useAuth();

  if (!auth) {
    toast.error("Please log in.");
    return <Navigate to="/" />;
  }

  return children;
};

const routesConfig = [
  { path: "/", element: <Home />, exact: true },
  { path: "/category/:product_id", element: <Search /> },
  { path: "/category/:cat_name/:product_id", element: <Search /> },
  { path: "/search", element: <WordSearch /> },
  {
    path: "/subcategory/:category1/:cat_name/:product_id",
    element: <Search />,
  },
  { path: "/recommended/:product_type", element: <Search /> },
  { path: "/product/:cat_name/:stock_id", element: <Product /> },
  {
    path: "/terms-and-conditions",
    element: <TermsAndConditions />,
    protected: true,
  },
  {
    path: "/test",
    element: <Test />,
  },

  {
    path: "/views/ticket/create",
    element: <CreateMessageTicket />,
    protected: true,
  },
  {
    path: "/views/ticket/type",
    element: <MessageTicket />,
    protected: true,
  },
  {
    path: "/views/ticket/all",
    element: <MessageTicketList />,
    protected: true,
  },
  {
    path: "/views/ticket/show",
    element: <MessageTicketDetails />,
    protected: true,
  },
  {
    path: "/views/ticket/message/add",
    element: <AddMessageToTicket />,
    protected: true,
  },
  {
    path: "/views/ticket/update",
    element: <MessageTicketUpdate />,
    protected: true,
  },

  { path: "/cart", element: <ShoppingCart /> },
  { path: "/checkout", element: <Checkout />, protected: true },
  { path: "/payment", element: <Payment />, protected: true },
  { path: "/payment/next", element: <PaymentNext />, protected: true },
  { path: "/airtime", element: <Airtime />, protected: true },
  { path: "/data", element: <Data />, protected: true },
  { path: "/bills", element: <Bills />, protected: true },
  { path: "/bills/tv", element: <PayTVIndex />, protected: true },
  { path: "/bills/history", element: <BillsHistory />, procected: true },
  { path: "/Receipt", element: <RecieptsIndex />, protected: true },
  { path: "bills/disco", element: <Disco />, protected: true },
  { path: "/callback/successful", element: <Extra /> },
  { path: "/callback/failed", element: <ExtraFailed /> },
  {
    path: "/terms-and-conditions",
    element: <TermsAndConditions />,
    protected: true,
  },
  {
    path: "/physical-Products",
    element: <PhysicalProduct2 />,
    procected: true,
  },
  { path: "/Vendor/:store_id", element: <VendorStore />, procected: true },

  {
    path: "/settings",
    element: (
      <ProtectedRoute>
        <SettingsLayout />
      </ProtectedRoute>
    ),
    children: [
      { index: true, element: <Navigate to="overview" /> }, // Default route
      { path: "overview", element: <OverviewFilter /> },
      { path: "payment", element: <PaymentFilter /> },
      { path: "message", element: <Message /> },
      { path: "wishlist", element: <Wishlist /> },
      { path: "history", element: <History /> },
      { path: "coupon", element: <Coupon /> },
      { path: "change-password", element: <ChangePassword /> },
      { path: "order-list", element: <OrdersParent /> },
      { path: "orders", element: <Orders /> },
      { path: "Orders/Physical/products", element: <OrderPhysical /> },
      { path: "order-track", element: <TrackOrders /> },
      { path: "Wallet-history", element: <WalletHistory /> },
      { path: "two-factor-authentication", element: <TwoFactor /> },
      { path: "shipping-address", element: <ShippingAddress /> },
      { path: "*", element: <Navigate to="overview" /> }, // Catch-all route
    ],
  },
  { path: "/settings/package-tracking", element: <PackageTracking /> },
  { path: "*", element: <Navigate to="/" /> }, // Global catch-all route
];

const AppRoutes = () => {
  return (
    <Suspense
      fallback={
        <div className="fixed w-screen bg-[white] h-screen flex items-center justify-center">
          <span className="loading loading-dots w-[10vw] min-w-[90px] text-darkGreen1"></span>
        </div>
      }
    >
      <Router>
        {routesConfig.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              route.protected ? (
                <ProtectedRoute>{route.element}</ProtectedRoute>
              ) : (
                route.element
              )
            }
          >
            {route.children &&
              route.children.map((child, idx) => (
                <Route
                  key={idx}
                  path={child.path}
                  index={child.index}
                  element={child.element}
                />
              ))}
          </Route>
        ))}
      </Router>
    </Suspense>
  );
};

export default AppRoutes;
