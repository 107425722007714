import { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { GoHeart, GoHeartFill } from "react-icons/go";
import GreyDot from "../assets/images/greyDot.png";
import { useNavigate } from "react-router-dom";
import { useWishlist } from "../hooks/useWishList";
import { toast } from "sonner";
import { useCart } from "../hooks/useCart";
import Product1 from "../assets/images/product1.png";
import { LuMinus } from "react-icons/lu";
import { IoAddOutline } from "react-icons/io5";
import { useAuth } from "../hooks/useAuth";
import { Button } from "react-scroll";
import { NoImage } from "../all_images";

export default function ProductCard({ product }) {
  const navigate = useNavigate();
  const { wishlist, dispatcher } = useWishlist();
  const { cart, addToCart, updateCart } = useCart();
  const { auth, getRandomThreeDigitNumber, isLoading } = useAuth();
  const [isInWishlist, setIsInWishlist] = useState(false);
  const [isInCart, setIsInCart] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [productLoading, setProductLoading] = useState(false);
  const itemId = useRef(null);

  useEffect(() => {
    setIsInWishlist(
      wishlist.some((item) => item.stock_id === product.stock_id)
    );
  }, [wishlist, product.stock_id]);

  const goToDetail = (id, name) => {
    const catUrl = name.replace(/ /g, "-").replace(/&/g, "and").toLowerCase();
    navigate(
      `/product/${catUrl}/${getRandomThreeDigitNumber()}${id}${getRandomThreeDigitNumber()}`
    );
  };

  const handleAddToWishlist = () => {
    if (!isInWishlist) {
      dispatcher({ type: "ADD_TO_WISHLIST", payload: { product } });
      toast.success(
        `${product?.product?.name || product?.name} added to wishlist`,
        {
          action: {
            label: "See Wishlist",
            onClick: () => navigate("/settings/wishlist"),
          },
        }
      );
    } else {
      dispatcher({
        type: "REMOVE_FROM_WISHLIST",
        payload: { productId: product.stock_id },
      });
      toast.info(`Removed from wishlist!`);
    }
  };

  useEffect(() => {
    const storedItemId = sessionStorage.getItem(`itemId_${product.stock_id}`);

    if (storedItemId) {
      itemId.current = storedItemId;
    }
  }, [product.stock_id]);

  useEffect(() => {
    const itemInCart = cart.find(
      (item) => Number(item.stock_id) === Number(product.stock_id)
    );

    setIsInCart(Boolean(itemInCart));

    setQuantity(itemInCart ? itemInCart.quantity : 0);
  }, [cart, product]);

  const handleAddToCart = async (stock_id) => {
    // if (!auth) {
    //   toast.error("Please log in to add items to cart");
    //   return;
    // }
    // console.log("adding to cart from card", stock_id);
    if (isInCart) {
      toast.success("Item is in cart");
    } else {
      setProductLoading(true);
      const response = await addToCart(stock_id, 1);
      setProductLoading(false);
      if (response) {
        toast.success(
          `${product?.product?.name || product?.name} added to cart`
        );

        itemId.current = response.id;

        sessionStorage.setItem(`itemId_${product.stock_id}`, response.stock_id);
      }
    }
  };

  const handleIncreaseQty = async (itemId) => {
    setProductLoading(true);
    await updateCart(itemId, "plus");
    setProductLoading(false);
  };

  const handleDecreaseQty = async (itemId) => {
    setProductLoading(true);
    await updateCart(itemId, "minus");
    setProductLoading(false);
  };

  const productImage =
    product.images[0]?.image_url || product.images[0]?.image_path || NoImage;

  const formattedStatus =
    product.status === "available" || product.status === "active"
      ? "Available"
      : "Out of Stock";

  return (
    <motion.div
      initial={{ filter: "drop-shadow(0 0 0 transparent)" }}
      whileHover={{
        filter:
          "drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1))",
        scale: 1.02,
      }}
      className="w-[155px] min-h-[244px] md:w-[282px] md:min-h-[388px] flex flex-col _items-center relative cursor-pointer group_ items-center"
      // className="col-4"
    >
      {formattedStatus === "Out of Stock" && (
        <div className="absolute top-[10px] left-[12px] z-20 rounded text-red1 p-[6px] bg-red2 bg-opacity-20">
          {formattedStatus}
        </div>
      )}
      {formattedStatus !== "Out of Stock" && (
        <motion.div
          whileTap={{ scale: 0.5 }}
          className="heart absolute top-[10px] right-[9px] lg:top-[12px] lg:right-[12px] flex justify-center items-center size-[32px] lg:size-[36px] rounded-full bg-[white] cursor-pointer z-[20]"
          onClick={handleAddToWishlist}
          aria-label={isInWishlist ? "Remove from wishlist" : "Add to wishlist"}
        >
          {isInWishlist ? (
            <GoHeartFill alt="heart" size={20} style={{ color: "red" }} />
          ) : (
            <GoHeart alt="heart-liked" size={20} />
          )}
        </motion.div>
      )}

      <div
        className="bg-lGrey2 w-full h-[140px] md:h-[280px] flex justify-center items-center rounded-[8px] mb-[23px] lg:mb-[16px] relative"
        onClick={() =>
          goToDetail(
            product.product_id,
            product?.product?.name || product?.name
          )
        }
      >
        {formattedStatus !== "Out of Stock" &&
          (isInCart ? (
            <div
              className="border border-darkGreen1 rounded-[8px] flex justify-between p-1 items-center gap-[10px] bg-[white] flex-1 min-w-fit w-full absolute bottom-0 lg:hidden_ lg:group-hover:flex"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <LuMinus
                className="size-[20px] md:size-[25px] cursor-pointer text-grey3"
                onClick={() => handleDecreaseQty(itemId.current)}
              />

              {productLoading ? (
                <span className="loading loading-spinner loading-xs"></span>
              ) : (
                <div className="text-[12px] leading-[19.2px] md:text-[16px] md:leading-[25.6px] select-none">
                  {quantity}
                </div>
              )}

              <button disabled={quantity === product.quantity - 1}>
                <IoAddOutline
                  className={`size-[20px] md:size-[25px] cursor-pointer  ${
                    quantity === product.quantity - 1
                      ? "text-grey3"
                      : "text-darkGreen3"
                  }`}
                  onClick={() => handleIncreaseQty(itemId.current)}
                />
              </button>
            </div>
          ) : (
            <motion.div
              whileTap={{ scale: 0.8 }}
              className="absolute bottom-0 w-full py-0.5 md:py-2 bg-darkGreen1 text-center text-[white] rounded mt-2 lg:hidden_ lg:group-hover:block"
              onClick={(e) => {
                e.stopPropagation();
                handleAddToCart(product.stock_id);
              }}
              aria-label="Add to cart"
            >
              {productLoading ? (
                <span className="loading loading-spinner loading-xs text-[white]"></span>
              ) : (
                "Add to cart"
              )}
            </motion.div>
          ))}

        {formattedStatus === "Out of Stock" && (
          <motion.div
            whileTap={{ scale: 0.8 }}
            className="absolute bottom-0 w-full py-0.5 md:py-2 bg-darkGreen1 text-center text-[white] rounded mt-2 lg:hidden_ lg:group-hover:block opacity-30"
            onClick={(e) => e.stopPropagation()}
            aria-label="Add to cart"
          >
            {productLoading ? (
              <span className="loading loading-spinner loading-xs text-[white]"></span>
            ) : (
              "Add to cart"
            )}
          </motion.div>
        )}

        <img
          src={productImage}
          alt={`${product?.product?.name || product?.name} image`}
          className="size-[90px] md:size-[180px]"
        />
      </div>
      <div>
        <div className="flex items-center justify-between w-[155px] md:w-[282px] mb-[4px]">
          <div className="text-[14px] md:text-[18px] md:leading-[25px] lg:text-[20px] leading-[19.6px] lg:leading-[28px] tracking-[-.2px] truncate">
            {product?.product?.name || product?.name}
          </div>
          <div className="text-darkGreen3 text-[14px] md:text-[18px] md:leading-[25px] lg:text-[20px] leading-[19.6px] lg:leading-[28px] tracking-[-.2px]">
            &#8358; {Number(product.price).toLocaleString()}
          </div>
        </div>
        <div className="text-grey1 text-[12px] md:text-[16px] leading-[19.2px] md:leading-[25.6px] mb-[8px]">
          {product.sku}
        </div>
        {/* <div className="flex gap-[8px] items-center">
          <img src={GreyDot} alt="" className="size-[4px]" />
          {/* <div className="text-[12px] md:text-[16px] leading-[19.2px] md:leading-[25.6px]">
            Quantity: {product.quantity}

          </div>
        </div> */}
      </div>
    </motion.div>
  );
}
