import { useEffect } from "react";
import { motion } from "framer-motion";

const Modal = ({ show, onClose, children }) => {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [show]);
  if (!show) return null;

  return (
    <div
      className="fixed top-0 left-0 right-0 bottom-0 bg-[#0B0F0E80] flex justify-center items-center z-[10000] max-xs:!mr-8"
      onClick={onClose}
    >
      <motion.div
        // initial={{ y: -50, x: 50 }}
        animate={{ y: [-100, 40, -30, 0] }}
        transition={{ duration: 0.5 }}
        className="bg-[white] w-[87%] max-xs:!w-[78%] max-w-[440px] rounded-[8px] pt-[32px] pb-[24px] px-[24px] max-h-[642px] overflow-auto no-scrollbar"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </motion.div>
    </div>
  );
};

export default Modal;
