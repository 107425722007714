import { createContext, useEffect, useState } from "react";
import { toast } from "sonner";
import { useAuth } from "../hooks/useAuth";
import { useProduct } from "../hooks/useProduct";
import axios from "../api/axios";
import { ApiRoutes } from "../tools/apiRoutes";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const { products } = useProduct();

  const randomNumber = () => {
    const timestamp = Date.now();
    const randomComponent = Math.floor(Math.random() * 100000);
    return `${timestamp}${randomComponent}`;
  };
  // const [cartUpdateStatus, setCartUpdateStatus] = useState(false);

  const [cart, setCart] = useState(() => {
    const storedCart = localStorage.getItem("joatech_cart");

    return storedCart ? JSON.parse(storedCart) : [];
  });
  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();
  // const [cartTracker, setCartTracker] = useState(sessionStorage.getItem('joatech_cart_tracker') ? JSON.parse(sessionStorage.getItem('joatech_cart_tracker')):[])
  const fetchCart = async () => {
    const cartInSession = sessionStorage.getItem("joatech_cart_tracker");

    if (auth) {
      if (cart && cart.length > 0 && !cartInSession) {
        setLoading(true);
        try {
          const cartArray = cart.map((cartItem) => ({
            stock_id: cartItem.stock_id,
            stockVariantId: cartItem.stockVariantId || null,
            qty: cartItem.quantity,
          }));

          const cartObjectToSend = { stockId: cartArray };
          // Send cart data to the server
          await addMultipleToCart(cartObjectToSend);
          // setCartUpdateStatus(true); // Mark cart as successfully updated
        } catch (error) {
          console.error("Error fetching cart:", error);
          // setCartUpdateStatus(false); // Mark cart update as failed
        } finally {
          setLoading(false); // Stop loading once the fetch completes
        }
      } else {
        const response = await axios.post(`/users/cart`);
        if (Number(response.data.status) === 200) {
          const fetchedCart = response.data.data;
          setCart(fetchedCart);
          localStorage.setItem("joatech_cart", JSON.stringify(fetchedCart));
          const cartInSession = sessionStorage.getItem("joatech_cart_tracker");
          if (cartInSession) {
            const cartslice = JSON.parse(cartInSession).slice();
            const updatedcart = [...cartslice, fetchedCart];

            sessionStorage.setItem(
              "joatech_cart_tracker",
              JSON.stringify(updatedcart)
            );
          } else {
            const updatedcart = [fetchedCart];
            sessionStorage.setItem(
              "joatech_cart_tracker",
              JSON.stringify(updatedcart)
            );
          }
        }
      }
    } else {
      // If user is not logged in, fetch cart from localStorage
      setLoading(true);
      try {
        const storedCart = localStorage.getItem("joatech_cart");
        if (storedCart) {
          const storedCartParsed = JSON.parse(storedCart);
          setCart(storedCartParsed);
        } else {
          setCart([]); // Set cart to an empty array if no stored cart
        }
      } catch (error) {
        console.error("Error fetching cart:", error);
      } finally {
        setLoading(false); // Stop loading after fetching localStorage cart
      }
    }
  };

  useEffect(() => {
    // Call the fetchCart function
    fetchCart();
  }, [auth]);

  // useEffect(() => {
  //   const fetchCart = async () => {
  //     if (auth) {
  //       setLoading(true);
  //       try {
  //         const response = await axios.post(`/users/cart`);
  //         if (Number(response.data.status) === 200) {
  //           const fetchedCart = response.data.data;
  //           setCart(fetchedCart);
  //           localStorage.setItem("cart", JSON.stringify(fetchedCart));
  //         }
  //       } catch (error) {
  //         console.error("Error fetching cart:", error);
  //       } finally {
  //         setLoading(false);
  //       }
  //     }
  //   };

  //   fetchCart();
  // }, [auth]);

  const addToCart = async (stockId, qty, stockVariantId = null) => {
    // if (!auth) {
    //   toast.error("Please log in to add items to your cart.");
    //   return;
    // }

    setLoading(true);
    try {
      const existingItemIndex = cart.findIndex(
        (item) => item.stock_id === stockId
      );

      if (existingItemIndex !== -1) {
        const updatedCart = [...cart];

        updatedCart[existingItemIndex] = {
          ...updatedCart[existingItemIndex],
          //if stockvariant exists
          stockVariantId: stockVariantId || null,
          quantity: updatedCart[existingItemIndex].quantity + qty,
          total:
            (updatedCart[existingItemIndex].quantity + qty) *
            parseFloat(updatedCart[existingItemIndex].price),
        };
        setCart(updatedCart);
        localStorage.setItem("joatech_cart", JSON.stringify(updatedCart));
        toast.success("Updated cart item!");
      } else if (auth) {
        const response = await axios.post("/users/cart/add", {
          stockId,
          qty,
          stockVariantId,
        });
        console.log("response from api", response);
        if (Number(response.data.status) === 200) {
          const updatedCart = [...cart, response.data.data];
          setCart(updatedCart);
          localStorage.setItem("joatech_cart", JSON.stringify(updatedCart));
          const cartInSession = sessionStorage.getItem("joatech_cart_tracker");
          const cartslice = JSON.parse(cartInSession).slice();
          const updatedcartTracker = [...cartslice, updatedCart];
          sessionStorage.setItem(
            "joatech_cart_tracker",
            JSON.stringify(updatedcartTracker)
          );

          toast.success("Added to cart!");
          return response.data.data;
        }
      } else {
        const productItem = products;

        const price = productItem.find(
          (product) => product.stock_id === stockId
        ).price;
        const generatedRandomNumber = randomNumber();
        const newCart = {
          id: generatedRandomNumber,
          stock_id: stockId,
          //stock variant
          stockVariantId: stockVariantId || null,
          quantity: qty,
          price: price,
          total: (qty * price).toFixed(2),
        };
        const updatedCart = [...cart, newCart];
        setCart(updatedCart);
        localStorage.setItem("joatech_cart", JSON.stringify(updatedCart));
        toast.success("Added to cart!");
        return newCart;
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      toast.error("Failed to add to cart.");
    } finally {
      setLoading(false);
    }
    return false;
  };

  const removeFromCart = async (itemId) => {
    // if (!auth) {
    //   toast.error("Please log in to remove items from your cart.");
    //   return;
    // }

    setLoading(true);
    try {
      if (auth) {
        const response = await axios.post(`/users/cart/remove`, { itemId });
        if (Number(response.data.status) === 200) {
          const updatedCart = cart.filter((item) => item.id !== Number(itemId));
          setCart(updatedCart);
          localStorage.setItem("joatech_cart", JSON.stringify(updatedCart));
          const cartInSession = sessionStorage.getItem("joatech_cart_tracker");
          const cartslice = JSON.parse(cartInSession).slice();
          const updatedcartTracker = [...cartslice, updatedCart];
          sessionStorage.setItem(
            "joatech_cart_tracker",
            JSON.stringify(updatedcartTracker)
          );

          toast.info("Removed from cart!");
        } else {
          toast.error(`${response.data.message}`);
        }
      } else {
        const updatedCart = cart.filter((item) => item.id !== itemId);
        setCart(updatedCart);
        localStorage.setItem("joatech_cart", JSON.stringify(updatedCart));
        toast.info("Removed from cart!");
      }
    } catch (error) {
      console.error("Error removing from cart:", error);
      toast.error("Failed to remove from cart.");
    } finally {
      setLoading(false);
    }
  };

  const updateCart = async (itemId, action) => {
    // if (!auth) {
    //   toast.error("Please log in to update your cart.");
    //   return;
    // }
    // await removeFromCart(itemId);
    // return;

    const cartItem = cart.find((item) => Number(item.id) === Number(itemId));

    setLoading(true);

    if (cartItem && Number(cartItem.quantity) === 1 && action === "minus") {
      await removeFromCart(itemId);
      return;
    }

    try {
      //code for auth before
      if (auth) {
        const response = await axios.post(`/users/cart/update`, {
          itemId,
          action,
        });
        if (Number(response.data.status) === 200) {
          const updatedItem = response.data.data;
          const updatedCart = cart.map((item) =>
            Number(item.id) === Number(itemId)
              ? {
                  ...item,
                  quantity: Number(updatedItem.quantity),
                  total: updatedItem.total,
                }
              : item
          );
          setCart(updatedCart);
          localStorage.setItem("joatech_cart", JSON.stringify(updatedCart));

          action === "plus"
            ? toast.success("Quantity increased!")
            : toast.info("Quantity decreased!");
          const cartInSession = sessionStorage.getItem("joatech_cart_tracker");
          const cartslice = JSON.parse(cartInSession).slice();
          const updatedcartTracker = [...cartslice, updatedCart];
          sessionStorage.setItem(
            "joatech_cart_tracker",
            JSON.stringify(updatedcartTracker)
          );
        } else {
          toast.error(`${response.data.message}`);
        }
      } else {
        const updatedCart = cart.map((item) => {
          if (item.id == itemId) {
            const newQuantity =
              action === "plus" ? item.quantity + 1 : item.quantity - 1;
            const newTotal = (item.price * newQuantity).toFixed(2);
            // Return a new object with updated quantity and total
            return {
              ...item,
              quantity: newQuantity,
              total: newTotal,
            };
          }
          return item;
        });

        setCart(updatedCart);
        localStorage.setItem("joatech_cart", JSON.stringify(updatedCart));
        action === "plus"
          ? toast.success("Quantity increased!")
          : toast.info("Quantity decreased!");
      }
    } catch (error) {
      console.error("Error updating cart:", error);
      toast.error("Failed to update cart.");
    } finally {
      setLoading(false);
    }
  };

  const addMultipleToCart = async (cartObject) => {
    if (auth) {
      try {
        setLoading(true);
        const response = await axios.post(
          ApiRoutes.addToCartMultiple,
          cartObject
        );

        if (response.data.status == 200) {
          const updatedCart = response.data.data;
          setCart(updatedCart);

          localStorage.setItem("joatech_cart", JSON.stringify(updatedCart));

          const cartInSession = sessionStorage.getItem("joatech_cart_tracker");
          if (cartInSession) {
            const cartslice = JSON.parse(cartInSession).slice();
            const updatedcartTracker = [...cartslice, updatedCart];
            sessionStorage.setItem(
              "joatech_cart_tracker",
              JSON.stringify(updatedcartTracker)
            );
          } else {
            const updatedcartTracker = [updatedCart];
            sessionStorage.setItem(
              "joatech_cart_tracker",
              JSON.stringify(updatedcartTracker)
            );
          }
        } else {
          toast.error("failed to update cart, please try again");
          console.error(response);
        }
      } catch (e) {
        console.error("Error adding multiple to cart: ", e);
        toast.error("Couldn't update cart, please try again");
      } finally {
        setLoading(false);
      }
    }
  };

  const clearCart = async () => {
    try {
      setLoading(true);
      const response = await axios.post(ApiRoutes.clearCart);
      if (response.data.status == 200) {
        setLoading(false);
      }
    } catch (e) {
      console.error("Error updating cart");
    } finally {
      setLoading(false);
    }
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        loading,
        addToCart,
        updateCart,
        removeFromCart,
        addMultipleToCart,
        clearCart,
        fetchCart,
        // setCartUpdateStatus,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
